import { FC, useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import { TableColumn } from '../../view-models/table-column';
import './columns-bar.scss';
import Toggle from '../../ui/toggle/toggle';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';

export interface ColumnsBarProps {
  columns: TableColumn[];
  setColumns: (cols: TableColumn[]) => void;
  localStorageColumns: LocalStorageVariableName;
}
const columnsBarCn = bemCN('columns-bar');

export const ColumnsBar: FC<ColumnsBarProps> = ({ columns, setColumns, localStorageColumns }) => {
  const [isAll, setIsAll] = useState(
    columns.filter((c: TableColumn) => c.isActive).length === columns.length ? true : false,
  );

  const onChangeActiveTable = (idCol: number, isActiveNew: boolean) => {
    let newCols = columns.map((col: TableColumn) => {
      if (col.id == idCol) return { ...col, isActive: isActiveNew };
      else return col;
    });

    newCols.filter((col: TableColumn) => col.isActive).length === columns.length
      ? setIsAll(true)
      : setIsAll(false);
    setColumns(newCols);
    localStorageService.setValue(localStorageColumns, newCols);
  };

  const onClickAllColums = (isNewAll: boolean) => {
    let newCols = columns.map((col: TableColumn) => {
      return {
        ...col,
        isActive:
          col.title === 'Артикул' || col.title === 'Название              Топ' || col.title === 'Название'  ? true : isNewAll,
      };
    });
    setColumns(newCols);
    localStorageService.setValue(localStorageColumns, newCols);

    setIsAll(isNewAll);
  };

  return (
    <div className={columnsBarCn()}>
      <div className={columnsBarCn('row')}>
        <span>Выбрать все</span>
        <Toggle isChecked={isAll} onChangeChecked={onClickAllColums} />
      </div>
      {[...columns]
        .filter(
          (c: TableColumn) =>
            c.title !== 'Артикул' &&
            c.title !== 'Название              Топ' &&
            c.title !== 'Название',
        )
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((col: TableColumn) => (
          <div key={col.id} className={columnsBarCn('row')}>
            <span>{col.title}</span>
            <Toggle
              isChecked={col.isActive}
              onChangeChecked={(isCheck: boolean) => onChangeActiveTable(col.id, isCheck)}
            />
          </div>
        ))}
    </div>
  );
};
