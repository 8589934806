export function formatFullDate(dateTime: string | Date): string {
  if (!dateTime) {
    return '';
  }
  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${days}.${month}.${year}`;
}

export function getDateTime(dateTime: string | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  let newDateTime: string = new Intl.DateTimeFormat('ru-RU', options).format(new Date(dateTime));
  return newDateTime;
}

export function toCalendarDate(dateTime?: string | Date): string | undefined {
  if (!dateTime) {
    return undefined;
  }

  const dateObj = new Date(dateTime);
  const days = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${year}-${month}-${days}`;
}
