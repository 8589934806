import { FC, useEffect, useState } from 'react';
import { RangeInputs } from '../../range-inputs/range-inputs';
import { bemCN } from '../../../configs/bem-classname';
import { CheckGroup, CheckMarkPosition } from '../../check-group/check-group';
import { SelectItem } from '../../../view-models/select-item';
import { Select, TypeSelectChild } from '../../select/select';

import './sellers-filter.scss';
import { Checkbox } from '../../../ui/checkbox/checkbox';
import { Input, TypeDataInput, TypeViewInput } from '../../../ui/input/input';
import { SellerFilterDto } from '../../../view-models/seller-filter-dto';
import { BrandDto, CatalogDto } from '../../../api/Api';
import { categoriesService } from '../../../services/categories-service';
import { brandService } from '../../../services/brands-service';
import { SearchInput } from '../../search-input/search-input';
import { FilterCategorySelect } from '../../category-selected-item/filter-category-select';
import { CategoryTree } from '../../../view-models/category';
import { defaultSellerStatFilter } from '../../../consts/default-filter-values';

export interface SellersFilterProps {
  filterValues: SellerFilterDto;
  setFilterValues: (newF: SellerFilterDto) => void;
}
const sellersFilterBarCn = bemCN('sellers-filter');
export const SellerFilter: FC<SellersFilterProps> = ({ filterValues, setFilterValues }) => {
  const [categories, setCategories] = useState<CategoryTree[]>();
  const [checkCategoryNames, setCheckCategoryNames] = useState<string[]>([]);

  const [searchValueBrand, setSearchValueBrand] = useState('');

  const [brands, setBrands] = useState<BrandDto[]>([]);
  const [selectBrands, setSelectBrands] = useState<BrandDto[]>([]);

  //Поиск в брендах
  const onChangeSearchBrand = (v: string) => {
    setSearchValueBrand(v);
    getBrandsByName(v);
  };

  useEffect(() => {
    getBrandsByName('A');
  }, [1]);

  // useEffect(() => {
  //   categoriesService.getCategories().then((res: CategoryTree[]) => {
  //     setCategories(res);
  //   });
  // }, [1]);

  const getBrandsByName = (v: string) => {
    brandService.getBrands({ name: v, limit: 10 }).then((res: BrandDto[]) => {
      setBrands(res);
      setSelectBrands(res.filter((b: BrandDto) => filterValues.brandIds?.includes(b.id || -1)));
    });
  };

  //Получение активных категорий для подписи в селектор
  const getActiveLabels = (cats: CategoryTree[]) => {
    let result: string[] = [];
    cats.map((c: CategoryTree, index: number) => {
      if (c.children) {
        let adding = getActiveLabels(c.children || []);
        result = result.concat(adding);
      } else {
        filterValues.categoryIds &&
          filterValues.categoryIds.includes(Number(c.value)) &&
          result.push(c.label);
      }
    });
    return result;
  };

  useEffect(() => {
    return () => {
      setSearchValueBrand('');
    };
  });

  useEffect(() => {
    if (JSON.stringify(filterValues) === JSON.stringify(defaultSellerStatFilter)) {
      setSelectBrands([]);
      getBrandsByName('A');
    }
  }, [filterValues]);

  return (
    <div className={sellersFilterBarCn()}>
      <div className={sellersFilterBarCn('isActivePanel')}>
        <span>Продавец</span>
        <div>
          <Checkbox
            isCheck={filterValues.active ? filterValues.active : false}
            onClick={() => setFilterValues({ ...filterValues, active: !filterValues.active })}
          />
          <span>Активный</span>
        </div>
      </div>
      <Input
        typeData={TypeDataInput.text}
        typeView={TypeViewInput.normal}
        value={filterValues.name || ''}
        placeholder="Название поставщика"
        onChange={(v: string) => setFilterValues({ ...filterValues, name: v })}
      />
      <Input
        typeData={TypeDataInput.number}
        typeView={TypeViewInput.normal}
        value={filterValues.inn || ''}
        placeholder="ИНН"
        onChange={(v: string) => setFilterValues({ ...filterValues, inn: v })}
      />
      {/* <div className={sellersFilterBarCn('selected')}>
        <span>Категория</span>
        <Select typeSelectChild={TypeSelectChild.check} activeNames={checkCategoryNames}>
          <div className={sellersFilterBarCn('category-container')}>
            <FilterCategorySelect
              checkedItems={filterValues.catalogsIds || []}
              setCheckedItems={(ids: number[]) => {
                setFilterValues({ ...filterValues, catalogsIds: ids });
              }}
              items={categories || []}
            />
          </div>
        </Select>
      </div> */}

      <div className={sellersFilterBarCn('selected')}>
        <span>Бренд</span>
        <Select
          typeSelectChild={TypeSelectChild.check}
          activeNames={selectBrands.map((item: BrandDto) => item.name || '')}
        >
          <SearchInput
            value={searchValueBrand}
            onChange={onChangeSearchBrand}
            onEnterClick={() => {}}
          />
          <CheckGroup
            items={(brands || [])
              .concat(selectBrands)
              .map((item: BrandDto) => new SelectItem(item.id || -1, item.name || ''))}
            checkMarkPosition={CheckMarkPosition.left}
            activeIds={filterValues.brandIds || []}
            onChange={(ids: number[]) => {
              setSelectBrands(brands.filter((br: BrandDto) => ids.includes(br.id || -1)));
              setFilterValues({ ...filterValues, brandIds: ids });
            }}
          />
        </Select>
      </div>
    </div>
  );
};
