import { bemCN } from '../../configs/bem-classname';
import './sidebar.scss';
import { ReactComponent as Logo } from '../../shared/image/svg/logo.svg';

import { menu } from '../../consts/sidebar-links';
import SideBarItem from './sidebar-item/sidebar-item';
import { useEffect, useState } from 'react';
import Toggle from '../../ui/toggle/toggle';
import { ReactComponent as Moon } from '../../shared/image/svg/moon.svg';
import { ReactComponent as Sun } from '../../shared/image/svg/sun.svg';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { Link } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';
//import { localStorageService } from '../../services/local-storage';

const sidebarCn = bemCN('sidebar');

const SideBar = () => {
  const [activeId, setActiveId] = useState<number | null>(null);

  //let theme = localStorageService.getStorageValue('theme');
  let theme = localStorageService.getValue(LocalStorageVariableName.theme);

  const [isChecked, setIsCheked] = useState(theme && theme === 'dark' ? false : true);

  const changeTheme = (themeName: string) => {
    //localStorageService.setStorageValue('theme', themeName);
    localStorageService.setValue(LocalStorageVariableName.theme, themeName);
  };

  useEffect(() => {
    isChecked ? changeTheme('light') : changeTheme('dark');
    let body = document.getElementsByTagName('body')[0];
    if (body) {
      isChecked ? body.classList.remove('_dark') : body.classList.add('_dark');
    }
  }, [isChecked]);

  return (
    <div className={sidebarCn()}>
      <Link to={linksUrl.analyitcs+linksUrl.analyticsProduct} className={sidebarCn('logo')}>
        <div>
          <Logo />
        </div>
        <div className="h2">STAT</div>
      </Link>
      {menu.map((item, i) => {
        return (
          <SideBarItem
            key={item.id}
            id={item.id}
            title={item.title}
            icon={item.icon}
            path={item?.path}
            links={item?.links}
            active={activeId === item.id}
            setActive={setActiveId}
          />
        );
      })}
      <div className={sidebarCn('toggle')}>
        <div className={sidebarCn("toggle-icon")}>
          <Sun />
        </div>
        <Toggle
          isChecked={!isChecked}
          onChangeChecked={(isCheck: boolean) => setIsCheked(!isCheck)}
        />
        <div className={sidebarCn("toggle-icon")}>
          <Moon />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
