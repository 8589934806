import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as ArrowBackIcon } from '../../shared/image/svg/arrow-back.svg';
import { ReactComponent as ExportIcon } from '../../shared/image/svg/export.svg';
import { ReactComponent as ColumnsIcon } from '../../shared/image/svg/columns.svg';
import { ReactComponent as FilterIcon } from '../../shared/image/svg/filter.svg';
import './seller-page.scss';
import { Pagination } from '../../components/pagination/pagination';
import { Table } from '../../ui/table/table';
import { ColumnsBar } from '../../components/columns-bar/columns-bar';
import { useEffect, useState } from 'react';
import { BarPanel } from '../../components/bar-panel/bar-panel';
import { TableColumn } from '../../view-models/table-column';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { FilterBar } from '../../components/filter-bar/filter-bar';
import BigLoader from '../../components/big-loader/big-loader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  BrandDto,
  ProductDto,
  ProductFilterDateDto,
  SellerDto,
  SellerStatisticsCardDto,
} from '../../api/Api';
import { defaultProductDateFilter } from '../../consts/default-filter-values';
import { ColorStatCard, StatCard, TypeStatCard } from '../../components/stat-card/stat-card';
import { productsService } from '../../services/products-service';
import { ProductFilter } from '../../components/filters/products-filter/products-filter';
import { Calendar } from '../../components/calendar/calendar';
import { CalendarPeriod } from '../../view-models/calendar-period';
import { useTable } from '../../hooks/useTable';
import { lastMonthRange, yeasteday } from '../../consts/calendar';
import { sellersService } from '../../services/sellers-service';
import { LineLoading } from '../../components/line-loading/line-loading';
import { TypeFile } from '../../view-models/type-files';
import { Export } from '../../components/export/export';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { TableLoad } from '../../components/table-load/table-load';

const sellerPageCn = bemCN('seller-page');

export const SellerPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isShowColumnsBar, setIsShowColumnsBar] = useState(false);
  const [isShowFilterBar, setIsShowFilterBar] = useState(false);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(
    localStorageService.getValue<TableColumn[]>(LocalStorageVariableName.productColumns) || [],
  );
  const [backCount, setBackCount] = useState(0);

  const [filterProducts, setFilterProducts] = useState<ProductFilterDateDto>({
    ...defaultProductDateFilter,
    supplierId: Number(id),
  });
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [sellerStat, setSellerStat] = useState<SellerStatisticsCardDto>();
  const [seller, setSeller] = useState<SellerDto>();
  const [countProduct, setCountProduct] = useState<number>();
  const [isLoadingFileExport, setIsLoadingFileExport] = useState(false);

  //Первоначальная загрузка товаров и продавца
  useEffect(() => {
    let startFilter = getParams();

    sellersService.getSeller(Number(id)).then((res: SellerDto) => {
      setSeller(res);
      setIsLoading(false);
    });
    tableColumns.length == 0 &&
      productsService.getColumns().then((res: TableColumn[]) => setTableColumns(res));
    getProducts(startFilter);
  }, [1]);

  //Получение продуктов
  const getProducts = async (filter: ProductFilterDateDto) => {
    // setIsLoading(true);
    setIsProductLoading(true);
    productsService.getProductsByDate({ ...filter, supplierId: Number(id) }).then((res: any) => {
      setProducts(res);
      setIsProductLoading(false);
    });
    // getStats({ ...filter, supplierId: Number(id) });
    setBackCount(backCount - 1);
    // getCountProducts({ ...filter, supplierId: Number(id) });
  };

  useEffect(() => {
    getStats({
      ...filterProducts,
      supplierId: Number(id),
      dateFrom: filterProducts.dateFrom,
      dateTo: filterProducts.dateTo,
      withSales: filterProducts.withSales,
    });
  }, [filterProducts.dateFrom, filterProducts.dateTo, filterProducts.withSales]);

  useEffect(() => {
    getCountProducts({ ...filterProducts, supplierId: Number(id) });
  }, [sellerStat]);

  const getCountProducts = async (filter: ProductFilterDateDto) => {
    if (filter.withSales === true) {
      setCountProduct(sellerStat?.productsWithSales || 0);
    } else {
      productsService.getProductCount(filter).then((res: number) => setCountProduct(res));
    }
  };
  //Получение данныех для карточек статистики
  const getStats = async (filter: ProductFilterDateDto) => {
    setSellerStat({});
    sellersService.getSellerStat(filter).then((res: any) => {
      setSellerStat(res as SellerStatisticsCardDto);
    });
  };

  //Подключение инструментов для работы с таблицей
  const { onPagination, onSort, getParams, saveParams, onChangeDate, pageNumber, setPageNumber } =
    useTable(
      countProduct || 0,
      filterProducts,
      defaultProductDateFilter,
      setFilterProducts,
      getProducts,
    );

  //Сброс фильтров
  const onResetFilter = () => {
    saveParams(defaultProductDateFilter);
    setFilterProducts(defaultProductDateFilter);
    getProducts(defaultProductDateFilter);
    setIsShowFilterBar(false);
  };

  //Применить фильтры
  const onApplyFilter = () => {
    saveParams(filterProducts);
    getProducts(filterProducts);
    setIsShowFilterBar(false);
  };

  //Клик на экспорт
  const onClickExport = (typeFile: TypeFile) => {
    setIsLoadingFileExport(true);
    productsService
      .startExportFileByDate(
        { ...filterProducts, supplierId: Number(id) },
        typeFile,
        tableColumns.map((col: TableColumn) => col.id),
      )
      .then((res: string) => {
        setIsLoadingFileExport(false);
        productsService.getFile(res, typeFile);
      });
  };

  if (isLoading) return <BigLoader color="dark" />;

  return (
    <div className={sellerPageCn()}>
      <div className={sellerPageCn('header')}>
        <Button
          onClick={() => navigate(backCount)}
          color={ColorsButton.transition}
          size={SizesButton.small}
          icon={<ArrowBackIcon />}
        />
        <p className="h1">Сводка по продавцу {seller?.name}</p>
        <Calendar
          changeValue={(v: CalendarPeriod) => {
            onChangeDate(v);
          }}
          value={
            new CalendarPeriod(
              new Date(filterProducts.dateFrom || lastMonthRange),
              new Date(filterProducts.dateTo || yeasteday),
            )
          }
          maxDate={yeasteday}
        />
      </div>
      <div className={sellerPageCn('info-stats')}>
        <div className={sellerPageCn('info-seller')}>
          <p>Продавец {seller?.name}</p>
          <p>
            Торговая марка: <span>{seller?.tradeMark}</span>
          </p>
          <p>
            ОГРН: <span>{seller?.ogrn}</span>
          </p>
          <p>
            ИНН: <span>{seller?.inn}</span>
          </p>
          <a target="_blank" href={seller?.url || '#'}>
            Посмотреть на Wildberries
          </a>
        </div>
        <div className={sellerPageCn('charts')}>
          <StatCard
            title="Выручка"
            value={
              sellerStat?.revenueSum || sellerStat?.revenueSum == 0 ? sellerStat?.revenueSum : -1
            }
            color={ColorStatCard.Blue}
            typeStatCard={TypeStatCard.grey}
            isLoad={sellerStat?.revenueSum === undefined ? true : false}
          />
          <StatCard
            title="Продажи"
            value={sellerStat?.salesSum || sellerStat?.salesSum == 0 ? sellerStat?.salesSum : -1}
            color={ColorStatCard.Green}
            typeStatCard={TypeStatCard.grey}
            isLoad={sellerStat?.salesSum === undefined ? true : false}
          />
          <StatCard
            title="Упущенная выручка"
            value={
              sellerStat?.loseRevenueSum || sellerStat?.loseRevenueSum == 0 ? sellerStat?.loseRevenueSum : -1
            }
            color={ColorStatCard.Orange}
            typeStatCard={TypeStatCard.grey}
            isLoad={sellerStat?.loseRevenueSum === undefined ? true : false}
          />
          <StatCard
            title="Товары с продажами"
            value={
              sellerStat?.productsWithSales || sellerStat?.productsWithSales == 0
                ? sellerStat?.productsWithSales
                : -1
            }
            color={ColorStatCard.Red}
            typeStatCard={TypeStatCard.grey}
            isLoad={sellerStat?.productsWithSales === undefined ? true : false}
          />
        </div>
      </div>

      <div className={sellerPageCn('buttons')}>
        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Фильтры"
          icon={<FilterIcon />}
          onClick={() => {
            setIsShowColumnsBar(false);
            setIsShowFilterBar(!isShowFilterBar);
          }}
        />
        <Export onClickExport={onClickExport} />
        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Выбрать колонки"
          icon={<ColumnsIcon />}
          onClick={() => {
            setIsShowFilterBar(false);
            setIsShowColumnsBar(!isShowColumnsBar);
          }}
        />
        <div className={sellerPageCn('checkbox')}>
          <Checkbox
            isCheck={filterProducts.withSales || false}
            onClick={() => {
              let newValue = filterProducts.withSales === true ? false : true;
              setFilterProducts({ ...filterProducts, withSales: newValue });
            }}
          />
          <span>Только товары с продажами</span>
        </div>
        <Pagination
          currentPageNumber={pageNumber}
          totalPagesCount={countProduct ? Math.ceil(countProduct / 50) : 1}
          onClick={onPagination}
        />
      </div>

      {tableColumns.length > 0 && (
        <>
          {isProductLoading ? (
            <TableLoad />
          ) : (
            <>
              <Table
                localStorageColumns={LocalStorageVariableName.productColumns}
                columns={tableColumns}
                data={products}
                onSort={onSort}
                idActiveSort={filterProducts.sortFieldId || 666}
                decs={filterProducts.desc || false}
              />
              <Pagination
                currentPageNumber={pageNumber}
                totalPagesCount={countProduct ? Math.ceil(countProduct / 50) : 1}
                onClick={onPagination}
              />
            </>
          )}

          <BarPanel
            title="Выбрать колонки"
            isShow={isShowColumnsBar}
            setIsShow={setIsShowColumnsBar}
          >
            <ColumnsBar
              localStorageColumns={LocalStorageVariableName.productColumns}
              setColumns={setTableColumns}
              columns={tableColumns}
            />
          </BarPanel>
          <BarPanel title="Фильтры" isShow={isShowFilterBar} setIsShow={setIsShowFilterBar}>
            <FilterBar onResetFilter={onResetFilter} onApplyFilter={onApplyFilter}>
              <ProductFilter filterValues={filterProducts} setFilterValues={setFilterProducts} />
            </FilterBar>
          </BarPanel>
        </>
      )}
      {isLoadingFileExport && <LineLoading />}
    </div>
  );
};
