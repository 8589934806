import {
  BrandFilterDto,
  FilterNavigationDto,
  HistoryOffsetDto,
  ProductCardStatisticsFilterDto,
  ProductFilterDateDto,
  ProductFilterDto,
} from '../api/Api';
import { SellerFilterDto } from '../view-models/seller-filter-dto';
import { toCalendarDate } from '../utils/date';
import { lastMonthRange, yeasteday } from './calendar';
import { ProfileHistoryFilter } from '../view-models/profile-history-filter';

export const defaultProductFilter: ProductFilterDto = {
  limit: 50,
  sortFieldId: 666,
  desc: false,
  offset: 0,
};

export const defaultProductDateFilter: ProductFilterDateDto = {
  limit: 50,
  sortFieldId: 666,
  desc: false,
  offset: 0,
  dateFrom: toCalendarDate(lastMonthRange),
  dateTo: toCalendarDate(yeasteday),
  withSales: true,
};

export const defaultSellerStatFilter: SellerFilterDto = {
  desc: true,
  limit: 50,
  sortFieldId: 108,
  active: true,
};

export const defaultSellerSubcategoryFilter:SellerFilterDto = {
  desc: true,
  limit: 10,
  sortFieldId: 108,
  dateFrom: toCalendarDate(lastMonthRange),
  dateTo: toCalendarDate(yeasteday),
  
}

export const defaultProductsFilterForSubcategory: ProductFilterDateDto = {
  limit: 50,
  sortFieldId: 666,
  desc: false,
  offset: 0,
  dateFrom: toCalendarDate(lastMonthRange),
  dateTo: toCalendarDate(yeasteday),
};

export const defaultBrandsFilter: BrandFilterDto = {
  desc: true,
  limit: 50,
  sortFieldId: 6,
  active: true,
};

export const defaultProfileHistoryFilter: ProfileHistoryFilter = {
  offset: 0,
  limit: 50,
};

export const defaultCategoryFilterDto: ProductFilterDto = {
  offset: 0,
  limit: 10,
};

export const defaultCategoryStatFilterDto: ProductFilterDateDto = {
  offset: 0,
  limit: 10,
  dateFrom: toCalendarDate(lastMonthRange),
  dateTo: toCalendarDate(yeasteday),
  withSales: true,
};

export const defaultFavoriteFilterDto: FilterNavigationDto = {
  offset: 0,
  limit: 10,
  desc: true,
  sortFieldId: 666,
};

export const defaultProductCardStatisticsFilterDto: ProductCardStatisticsFilterDto = {
  dateFrom: toCalendarDate(lastMonthRange),
  dateTo: toCalendarDate(yeasteday),
};
