import { useEffect, useState } from 'react';
import { CategorySelectedItem } from '../../components/category-selected-item/category-selected-item';
import { bemCN } from '../../configs/bem-classname';
import './categories-page.scss';
import { categoriesService } from '../../services/categories-service';
import { CategoryTree } from '../../view-models/category';
import { SearchInput } from '../../components/search-input/search-input';
import { SearchCategorySelectedItem } from '../../components/category-selected-item/search-category-selected-item';

import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import BigLoader from '../../components/big-loader/big-loader';
import BarChart, { ColorsBarChart } from '../../ui/bar-chart/bar-chart';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { useNavigate } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';

const categoriesPageCn = bemCN('categories-page');

export const CategoriesPage = () => {
  const [categories, setCategories] = useState<CategoryTree[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [filterCategories, setFilterCategories] = useState<CategoryTree[]>();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [activrCategoryId, setActiveCategoryId] = useState<string>();
  const navigate = useNavigate();
  const [checkedIds, setCheckedIds] = useState<string[]>();


  useEffect(() => {
    categoriesService.getCategories().then((res: CategoryTree[]) => {
      setCategories(res);
      setIsLoading(false);
    });
  }, [1]);

  const onChangeSeacrhInput = (v: string) => {
    const extractVal = (items: CategoryTree[]): CategoryTree[] => {
      return items
        .map((item: CategoryTree) => {
          return { ...item, children: extractVal((item.children as CategoryTree[]) || []) };
        })
        .filter(
          (item: CategoryTree) =>
            (item.children && item.children.length > 0) ||
            item.label.toLowerCase().includes(v.toLowerCase()),
        );
    };
    let newFilterCategories: CategoryTree[] = extractVal(categories || []);
    setFilterCategories(newFilterCategories);
    setSearchInputValue(v);
    setActiveCategoryId(undefined);
  };

  const onChange = (idCategory: string, ids: string[]) => {
    //console.log(ids);
    setActiveCategoryId(idCategory);
    setCheckedIds(ids);
    if (ids.length == 0) {
      setActiveCategoryId(undefined);
    }
  };

  const onClickApply = () => {
     checkedIds && navigate(linksUrl.subcategory + '?themeCategories=' + checkedIds.join(','));
  };


  if (isLoading) return <BigLoader />;
  return (
    <div className={categoriesPageCn()}>
      <div className={categoriesPageCn('header')}>
        <p className="h1">Все категории</p>
        {activrCategoryId && (
          <Button onClick={onClickApply} size={SizesButton.big} color={ColorsButton.violet} text="Применить" />
        )}
        <SearchInput
          placeholder="Поиск по категориям"
          value={searchInputValue}
          onChange={onChangeSeacrhInput}
          onEnterClick={() => {}}
        />
      </div>
      <div className={categoriesPageCn('categories')}>
        {categories &&
          searchInputValue === '' &&
          categories.map((category: CategoryTree, index: number) => {
            return (
              <CategorySelectedItem
                key={category.value}
                id={category.value?.toString()}
                text={category.label || ''}
                count={category.countProducts || 0}
                items={category.children || []}
              />
            );
          })}
        {filterCategories &&
          searchInputValue !== '' &&
          filterCategories.map((category: CategoryTree, index: number) => {
            return (
              <SearchCategorySelectedItem
                key={category.value}
                id={category.value?.toString()}
                text={category.label || ''}
                items={category.children || []}
                isDisable={activrCategoryId && activrCategoryId !== category.value ? true : false}
                onChange={(ids: string[]) => onChange(category.value, ids)}

              />
            );
          })}
      </div>
      <div className={categoriesPageCn('bar-chart')}>
        <p className="h1">Статистика по товарам</p>
        <BarChart
          color={ColorsBarChart.colorful}
          labelY="Количество товаров, шт."
          isNormalLabel
          isShortLable
          unit="шт."
          data={
            categories
              ? categories.map(
                  (category: CategoryTree) =>
                    new ChartDataItem(
                      Number(category.value) || 0,
                      category.label || '',
                      category.countProducts || '',
                    ),
                )
              : []
          }
        />
      </div>
    </div>
  );
};
