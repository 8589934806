import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RootPage } from '../pages/root-page/root-page';
import { Suspense } from 'react';
import { ProductsPage } from '../pages/products-page/products-page';
import { AnalyticsPage } from '../pages/analytics-page/analytics-page';
import { CategoriesPage } from '../pages/categories-page/categories-page';
import { SellersPage } from '../pages/sellers-page/sellers-page';
import { BrandsPage } from '../pages/brands-page/brands-page';
import { StartPage } from '../pages/start-page/start-page';
import { LoginPage } from '../pages/login-page/login-page';
import { BrandPage } from '../pages/brand-page/brand-page';
import { SellerPage } from '../pages/seller-page/seller-page';
import { ProductPage } from '../pages/product-page/product-page';
import { ErrorDevelopmentPage } from '../pages/error-developmant-page/error-developmant-page';
import BigLoader from '../components/big-loader/big-loader';
import { SubcategoryPage } from '../pages/subcategory-page/subcategory-page';
import { ProfilePage } from '../pages/profile-page/profile-page';
import { ProfileInfoPage } from '../pages/profile-info-page/profile-info-page';
import { ProfileTariffsPage } from '../pages/profile-tariffs-page/profile-tariffs-page';
import { ProfileHistoryPage } from '../pages/profile-history-page/profile-history-page';
import { ProfileKeysPage } from '../pages/profile-keys-page/profile-keys-page';
import { InstructionsPage } from '../pages/instructions-page/instructions-page';
import { FavoritesPage } from '../pages/favorites-page/favorites-page';
import { HelpPage } from '../pages/help-page/help-page';
import { linksUrl } from '../consts/linksUrl';
import { RegistationPage } from '../pages/registation-page/registation-page';
import { RecoveryPasswordPage } from '../pages/recovery-password-page/recovery-password-page';

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      element: <RootPage />,
      children: [
        {
          path: '/analytics',
          element: <AnalyticsPage />,
          children: [
            {
              path: 'products',
              element: <ProductsPage />,
            },
            {
              path: 'categories',
              element: <CategoriesPage />,
            },
            {
              path: 'sellers',
              element: <SellersPage />,
            },
            {
              path: 'brands',
              element: <BrandsPage />,
            },
          ],
        },
        {
          path: '/brand/:id',
          element: <BrandPage />,
        },
        {
          path: '/seller/:id',
          element: <SellerPage />,
        },
        {
          path: '/product/:id',
          element: <ProductPage />,
        },
        {
          path: '/subcategory',
          element: <SubcategoryPage />,
        },
        {
          path: '/favorite',
          element: <FavoritesPage />,
        },
        {
          path: '/help',
          element: <HelpPage />,
        },
        {
          path: '/profile',
          element: <ProfilePage />,
          children: [
            {
              path: 'info',
              element: <ProfileInfoPage />,
            },
            {
              path: 'tariffs',
              element: <ProfileTariffsPage />,
            },
            {
              path: 'history',
              element: <ProfileHistoryPage />,
            },
            {
              path: 'keys',
              element: <ProfileKeysPage />,
            },
          ],
        },
        {
          path: '/promotion/manager',
          element: <ErrorDevelopmentPage />,
        },
        {
          path: '*',
          element: <ErrorDevelopmentPage />,
        },

        {
          path: '/instructions',
          element: <InstructionsPage />,
        },
      ],
    },
    {
      path: '/',
      element: <StartPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: linksUrl.register,
      element: <RegistationPage />,
    },
    {
      path: linksUrl.passwordRecovery,
      element: <RecoveryPasswordPage />,
    },
  ]);

  return (
    <Suspense fallback={<BigLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default AppRouter;
