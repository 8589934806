import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as ArrowBackIcon } from '../../shared/image/svg/arrow-back.svg';
import { ReactComponent as ExportIcon } from '../../shared/image/svg/export.svg';
import { ReactComponent as ColumnsIcon } from '../../shared/image/svg/columns.svg';
import { ReactComponent as FilterIcon } from '../../shared/image/svg/filter.svg';
import './brand-page.scss';
import { Pagination } from '../../components/pagination/pagination';
import { Table } from '../../ui/table/table';
import { ColumnsBar } from '../../components/columns-bar/columns-bar';
import { useEffect, useState } from 'react';
import { BarPanel } from '../../components/bar-panel/bar-panel';
import { TableColumn } from '../../view-models/table-column';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { FilterBar } from '../../components/filter-bar/filter-bar';
import BigLoader from '../../components/big-loader/big-loader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BrandDto, ProductDto, ProductFilterDateDto } from '../../api/Api';
import { defaultProductDateFilter } from '../../consts/default-filter-values';
import { brandService } from '../../services/brands-service';
import { ColorStatCard, StatCard, TypeStatCard } from '../../components/stat-card/stat-card';
import { productsService } from '../../services/products-service';
import { ProductFilter } from '../../components/filters/products-filter/products-filter';
import { BrandsStats } from '../../view-models/brands-stats';
import { Calendar } from '../../components/calendar/calendar';
import { CalendarPeriod } from '../../view-models/calendar-period';
import { useTable } from '../../hooks/useTable';
import { lastMonthRange, yeasteday } from '../../consts/calendar';
import { LineLoading } from '../../components/line-loading/line-loading';
import { Export } from '../../components/export/export';
import { TypeFile } from '../../view-models/type-files';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { TableLoad } from '../../components/table-load/table-load';

const brandPageCn = bemCN('brand-page');

export const BrandPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const [isShowColumnsBar, setIsShowColumnsBar] = useState(false);
  const [isShowFilterBar, setIsShowFilterBar] = useState(false);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(
    localStorageService.getValue<TableColumn[]>(LocalStorageVariableName.productColumns) || [],
  );
  const [filterProducts, setFilterProducts] = useState<ProductFilterDateDto>({
    ...defaultProductDateFilter,
    brandIds: [Number(id)],
  });
  const [backCount, setBackCount] = useState(0);

  const [products, setProducts] = useState<ProductDto[]>([]);
  const [brandsStat, setBrandsStat] = useState<BrandsStats>(new BrandsStats());
  const [brandName, setBrandName] = useState<string>('');
  const [isLoadingFileExport, setIsLoadingFileExport] = useState(false);
  const [countProduct, setCountProduct] = useState(0);

  //Первоначальная загрузка товаров и названия бренда
  useEffect(() => {
    let startFilter = getParams();
    brandService.getBrand(Number(id)).then((res: BrandDto) => setBrandName(res?.name || ' '));
    tableColumns.length == 0 &&
      productsService.getColumns().then((res: TableColumn[]) => setTableColumns(res));
    getProducts(startFilter);
  }, [1]);

  //Получение продуктов
  const getProducts = async (filter: ProductFilterDateDto) => {
    // setIsLoading(true);
    setIsProductLoading(true);
    productsService.getProductsByDate({ ...filter, brandIds: [Number(id)] }).then((res: any) => {
      setProducts(res);
      // setIsLoading(false);
      setIsProductLoading(false);
    });

    setBackCount(backCount - 1);
    //  getCountProducts({ ...filter, brandIds: [Number(id)] });
  };

  useEffect(() => {
    getStats({
      ...filterProducts,
      brandIds: [Number(id)],
      dateFrom: filterProducts.dateFrom,
      dateTo: filterProducts.dateTo,
      withSales: filterProducts.withSales,
    });
  }, [filterProducts.dateFrom, filterProducts.dateTo, filterProducts.withSales]);

  //Получение данныех для карточек статистики
  const getStats = async (filter: ProductFilterDateDto) => {
    setBrandsStat(new BrandsStats());
    brandService.getBrandStat(filter).then((res: any) => {
      setBrandsStat(res as BrandsStats);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getCountProducts({ ...filterProducts, brandIds: [Number(id)] });
  }, [brandsStat]);
  const getCountProducts = async (filter: ProductFilterDateDto) => {
    if (filter.withSales === true) {
      setCountProduct(brandsStat.productsCount || 0);
    } else {
      productsService.getProductCount(filter).then((res: number) => setCountProduct(res));
    }
  };

  //Подключение инструментов для работы с таблицей
  const { onPagination, onSort, getParams, saveParams, onChangeDate, pageNumber, setPageNumber } =
    useTable(
      countProduct || 0,
      filterProducts,
      defaultProductDateFilter,
      setFilterProducts,
      getProducts,
    );

  //Сброс фильтров
  const onResetFilter = () => {
    saveParams(defaultProductDateFilter);
    setFilterProducts(defaultProductDateFilter);
    getProducts(defaultProductDateFilter);
    getStats(defaultProductDateFilter);
    setIsShowFilterBar(false);
  };

  //Применить фильтры
  const onApplyFilter = () => {
    saveParams(filterProducts);
    getProducts(filterProducts);
    getStats(filterProducts);
    setIsShowFilterBar(false);
  };

  //Клик на экспорт
  const onClickExport = (typeFile: TypeFile) => {
    setIsLoadingFileExport(true);
    productsService
      .startExportFileByDate(
        { ...filterProducts, brandIds: [Number(id)] },
        typeFile,
        tableColumns.map((col: TableColumn) => col.id),
      )
      .then((res: string) => {
        setIsLoadingFileExport(false);
        productsService.getFile(res, typeFile);
      });
  };

  if (isLoading) return <BigLoader color="dark" />;

  return (
    <div className={brandPageCn()}>
      <div className={brandPageCn('header')}>
        <Button
          onClick={() => navigate(backCount)}
          color={ColorsButton.transition}
          size={SizesButton.small}
          icon={<ArrowBackIcon />}
        />
        <p className="h1">Сводка по бренду {brandName || ' - '}</p>
        <Calendar
          changeValue={(v: CalendarPeriod) => {
            onChangeDate(v);
          }}
          value={
            new CalendarPeriod(
              new Date(filterProducts.dateFrom || lastMonthRange),
              new Date(filterProducts.dateTo || yeasteday),
            )
          }
          maxDate={yeasteday}
        />
      </div>
      <div className={brandPageCn('charts')}>
        <StatCard
          title="Выручка"
          value={brandsStat.revenue || brandsStat.revenue == 0 ? brandsStat.revenue : -1}
          color={ColorStatCard.Blue}
          typeStatCard={TypeStatCard.grey}
          isLoad={brandsStat.revenue === undefined ? true : false}
        />
        <StatCard
          title="Продажи"
          value={brandsStat.sale || brandsStat.sale == 0 ? brandsStat.sale : -1}
          color={ColorStatCard.Green}
          typeStatCard={TypeStatCard.grey}
          isLoad={brandsStat.sale === undefined ? true : false}
        />
        <StatCard
          title="Упущенная выручка"
          value={
            brandsStat.lostRevenue || brandsStat.lostRevenue == 0 ? brandsStat.lostRevenue : -1
          }
          color={ColorStatCard.Orange}
          typeStatCard={TypeStatCard.grey}
          isLoad={brandsStat.lostRevenue === undefined ? true : false}
        />
        <StatCard
          title="Товары с продажами"
          value={
            brandsStat.productsCount || brandsStat.productsCount == 0
              ? brandsStat.productsCount
              : -1
          }
          color={ColorStatCard.Red}
          typeStatCard={TypeStatCard.grey}
          isLoad={brandsStat?.productsCount === undefined ? true : false}
        />
        <StatCard
          title="Продавцы"
          value={
            brandsStat.sellerCount || brandsStat.sellerCount == 0 ? brandsStat.sellerCount : -1
          }
          color={ColorStatCard.Violet}
          typeStatCard={TypeStatCard.grey}
          isLoad={brandsStat?.sellerCount === undefined ? true : false}
        />
      </div>
      <div className={brandPageCn('buttons')}>
        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Фильтры"
          icon={<FilterIcon />}
          onClick={() => {
            setIsShowColumnsBar(false);
            setIsShowFilterBar(!isShowFilterBar);
          }}
        />
        <Export onClickExport={onClickExport} />

        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Выбрать колонки"
          icon={<ColumnsIcon />}
          onClick={() => {
            setIsShowFilterBar(false);
            setIsShowColumnsBar(!isShowColumnsBar);
          }}
        />
        <div className={brandPageCn('checkbox')}>
          <Checkbox
            isCheck={filterProducts.withSales || false}
            onClick={() => {
              let newValue = filterProducts.withSales === true ? false : true;
              setFilterProducts({ ...filterProducts, withSales: newValue });
            }}
          />
          <span>Только товары с продажами</span>
        </div>
        <Pagination
          currentPageNumber={pageNumber}
          totalPagesCount={countProduct ? Math.ceil(countProduct / 50) : 1}
          onClick={onPagination}
        />
      </div>

      {tableColumns.length > 0 && (
        <>
          {isProductLoading ? (
            <TableLoad />
          ) : (
            <>
              <Table
                localStorageColumns={LocalStorageVariableName.productColumns}
                columns={tableColumns}
                data={products}
                onSort={onSort}
                idActiveSort={filterProducts.sortFieldId || 666}
                decs={filterProducts.desc || false}
              />
              <Pagination
                currentPageNumber={pageNumber}
                totalPagesCount={countProduct ? Math.ceil(countProduct / 50) : 1}
                onClick={onPagination}
              />
            </>
          )}

          <BarPanel
            title="Выбрать колонки"
            isShow={isShowColumnsBar}
            setIsShow={setIsShowColumnsBar}
          >
            <ColumnsBar
              localStorageColumns={LocalStorageVariableName.productColumns}
              setColumns={setTableColumns}
              columns={tableColumns}
            />
          </BarPanel>
          <BarPanel title="Фильтры" isShow={isShowFilterBar} setIsShow={setIsShowFilterBar}>
            <FilterBar onResetFilter={onResetFilter} onApplyFilter={onApplyFilter}>
              <ProductFilter
                isHideBrand
                filterValues={filterProducts}
                setFilterValues={setFilterProducts}
              />
            </FilterBar>
          </BarPanel>
        </>
      )}
      {isLoadingFileExport && <LineLoading />}
    </div>
  );
};
