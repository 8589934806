import { ColDef, ITooltipParams, SortDirection } from 'ag-grid-community';
import { localStorageService, LocalStorageVariableName } from './localStorage-service';
import { TableColumn } from '../view-models/table-column';
import { TableItem } from '../ui/table/table-item/table-item';
import { PathDto } from '../api/Api';

const iconsSort = {
  sortUnSort:
    "<svg width='10' height='15' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 19.688L0.803847 12.188L11.1962 12.188L6 19.688Z' fill='#7B7983'/><path d='M6 0.687988L0.803848 8.18799L11.1962 8.18799L6 0.687988Z' fill='#7B7983'/></svg>",
  sortDescending:
    "<svg width='10' height='15' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 19.688L0.803847 12.188L11.1962 12.188L6 19.688Z' fill='#7B7983'/><path d='M6 0.687988L0.803848 8.18799L11.1962 8.18799L6 0.687988Z' fill='#bcbcbc'/></svg>",
  sortAscending:
    "<svg width='10' height='15' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 19.688L0.803847 12.188L11.1962 12.188L6 19.688Z' fill='#bcbcbc'/><path d='M6 0.687988L0.803848 8.18799L11.1962 8.18799L6 0.687988Z' fill='#7B7983'/></svg>",
};

class TableService {
  async getColDefs(
    tableColumns: TableColumn[],
    tableEntity: LocalStorageVariableName,
    idActiveSort: number,
    decs: boolean,
    isSimpleTable?: boolean,
  ) {
    let saveColumns: TableColumn[] = localStorageService.getValue<TableColumn[]>(tableEntity) || [];
    let currentDesc: SortDirection = decs ? 'desc' : 'asc';
    let colDefs: ColDef[] = tableColumns
      .filter((tb: TableColumn) => tb.isActive)
      .map((t: TableColumn) => {
        if (t.isSort) {
          return {
            field: t.name.toLowerCase() || '',
            colId: t.id?.toString(),
            sortable: true,
            headerName: t.title || 'Поле № ' + t.id?.toString(),
            icons: iconsSort,
            comparator: () => 0,
            lockVisible: true,
            width: saveColumns.find((tc: TableColumn) => tc.id == t.id)?.width,
            // cellRenderer: t.isLink ? TableItem : undefined,
            cellRenderer: TableItem,
            cellRenderParams: { typeEntity: tableEntity },
            sort: t.id == idActiveSort ? currentDesc : null,
            resizable: isSimpleTable ? false : true,
            suppressDragLeaveHidesColumns: true,
            suppressMovable: isSimpleTable ? true : false,
            tooltipField: t.name.toLowerCase(),
            flex: t.width ? Number.NaN : 1,
            minWidth: 100,
          };
        } else {
          return {
            field: t.name.toLocaleLowerCase() || '',
            colId: t.id?.toString(),
            sortable: false,
            headerName: t.title || 'Поле № ' + t.id?.toString(),
            lockVisible: true,
            width: saveColumns.find((tc: TableColumn) => tc.id == t.id)?.width,
            // cellRenderer: t.isLink ? TableItem : undefined,
            cellRenderer: TableItem,
            cellRenderParams: { typeEntity: tableEntity },
            resizable: isSimpleTable ? false : true,
            suppressDragLeaveHidesColumns: true,
            suppressMovable: isSimpleTable ? true : false,
            tooltipField: t.title === 'Категория' ? undefined : t.name.toLowerCase(),
            flex: t.width ? Number.NaN : 1,
            minWidth: 100,
            tooltipValueGetter:
              t.title === 'Категория'
                ? (p: ITooltipParams) => {
                    let obj = p.data;
                    let text: string = '';
                    if (obj.path) {
                      text = obj.path.map((item: PathDto) => item.name as string).join(' / ');
                    } else {
                      text = obj.category;
                    }
                    return text;
                  }
                : undefined,
          };
        }
      });
    return colDefs;
  }

  async getRowDefs(objs: any[], tableColumns: TableColumn[]) {
    const data: any[] = objs.map((o: any) => {
      let newObj: any = {};
      Object.keys(o).forEach(function (key) {
        newObj[key.toLocaleLowerCase()] = o[key];
      });
      return newObj;
    });
    return data;
  }
}

export const tableService: TableService = new TableService();
