import { FC } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './info-table.scss';
import { TableColumn } from '../../view-models/table-column';
import { Link } from 'react-router-dom';

export interface InfoTableProps {
  columns: TableColumn[];
  data: any[];
  linkTemplate:string;
}

const infoTableCn = bemCN('info-table');

export const InfoTable: FC<InfoTableProps> = ({ columns, data, linkTemplate}) => {
  return (
    <table className={infoTableCn()}>
      <thead>
        <tr>
          {columns.map((col: TableColumn) => (
            <th key={col.id}>{col.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item: any) => (
          <tr key={item.id}>
            {columns.map((col: TableColumn) => {
              if (col.name === 'color') {
                return (
                  <td key={item.id + '_' + col.id}>
                    <div></div>
                  </td>
                );
              } else if (Number(item[col.name])) {
                return (
                  <td key={item.id + '_' + col.id}>
                    {Number(item[col.name]).toLocaleString('ru-RU')}
                  </td>
                );
              } else {
                return (
                  <td key={item.id + '_' + col.id}>
                    <Link to={linkTemplate+"/" + item.id}>{item[col.name]}</Link>
                  </td>
                );
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
