import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as FilterIcon } from '../../shared/image/svg/filter.svg';
import { ReactComponent as ExportIcon } from '../../shared/image/svg/export.svg';
import { ReactComponent as ColumnsIcon } from '../../shared/image/svg/columns.svg';
import './brands-page.scss';
import { DirectionPaginationClick, Pagination } from '../../components/pagination/pagination';
import { Table } from '../../ui/table/table';
import { ColumnsBar } from '../../components/columns-bar/columns-bar';
import { useEffect, useState } from 'react';
import { BarPanel } from '../../components/bar-panel/bar-panel';
import { TableColumn } from '../../view-models/table-column';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { FilterBar } from '../../components/filter-bar/filter-bar';
import { BrandFilter } from '../../components/filters/brands-filter/brands-filter';
import { PieChart } from '../../ui/pie-chart/pie-chart';
import { SearchInput } from '../../components/search-input/search-input';
import BigLoader from '../../components/big-loader/big-loader';
import { brandsInfoTableColumns, sellersInfoTableColumns } from '../../consts/info-tables-columns';
import { BrandDto, BrandFilterDto } from '../../api/Api';
import { InfoTable } from '../../components/info-table/info-table';
import { defaultBrandsFilter } from '../../consts/default-filter-values';
import { infoTableColors } from '../../consts/info-table-colors';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { brandService } from '../../services/brands-service';
import { linksUrl } from '../../consts/linksUrl';
import { useTable } from '../../hooks/useTable';
import { LineLoading } from '../../components/line-loading/line-loading';
import { Export } from '../../components/export/export';
import { TypeFile } from '../../view-models/type-files';
import { TableLoad } from '../../components/table-load/table-load';

const brandsPageCn = bemCN('brands-page');

export const BrandsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBrandsLoading, setIsBrandsLoading] = useState(false);

  const [isShowColumnsBar, setIsShowColumnsBar] = useState(false);
  const [isShowFilterBar, setIsShowFilterBar] = useState(false);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(
    localStorageService.getValue(LocalStorageVariableName.brandColumn) || [],
  );
  const [brandStat, setBrandStat] = useState<BrandDto[]>([]);
  const [filterBrands, setFilterBrands] = useState<BrandFilterDto>({
    ...defaultBrandsFilter,
  });
  const [brands, setBrands] = useState<BrandDto[]>([]);
  const [isLoadingFileExport, setIsLoadingFileExport] = useState(false);

  const [countBrands, setCountBrands] = useState<number>();

  useEffect(() => {
    let startFilter = getParams();
    tableColumns.length == 0 &&
      brandService.getColumns().then((res: TableColumn[]) => setTableColumns(res));
    getBrands(startFilter);
    getBrandsStat(startFilter);
  }, [1]);

  //Сброс фильтров
  const onResetFilter = () => {
    saveParams(defaultBrandsFilter);
    setFilterBrands(defaultBrandsFilter);
    getBrands(defaultBrandsFilter);
    getBrandsStat(defaultBrandsFilter);
    setIsShowFilterBar(false);
  };

  //Применить фильтры
  const onApplyFilter = () => {
    saveParams({ ...filterBrands, offset: 0 });
    getBrands({ ...filterBrands, offset: 0 });
    getBrandsStat({ ...filterBrands, offset: 0 });
    setIsShowFilterBar(false);
  };

  //Получение брендов
  const getBrands = (filter: BrandFilterDto) => {
    // setIsLoading(true);
    setIsBrandsLoading(true);

    brandService.getBrands(filter).then((res: any) => {
      setBrands(res);
      // setIsLoading(false);
      setIsBrandsLoading(false);
    });
    brandService.getBrandsCount(filter).then((res: number) => setCountBrands(res));
  };

  //Получение данныех для статистики
  const getBrandsStat = async (filter: BrandFilterDto) => {
    setBrandStat([]);
    setIsLoading(true);
    brandService.getBrandsStat(filter).then((res: BrandDto[]) => {
      setBrandStat(res);
      setIsLoading(false);
    });
  };

  //Подключение инструментов для работы с таблицей
  const { onPagination, onSort, getParams, saveParams, pageNumber, setPageNumber } = useTable(
    countBrands || 0,
    filterBrands,
    defaultBrandsFilter,
    setFilterBrands,
    getBrands,
  );

  //Обработка поиска
  const onSearchInputEnter = () => {
    onApplyFilter();
  };

  //Клик на экспорт
  const onClickExport = (typeFile: TypeFile) => {
    setIsLoadingFileExport(true);
    brandService
      .startExportFile(
        filterBrands,
        typeFile,
        tableColumns.map((col: TableColumn) => col.id),
      )
      .then((res: number) => {
        setIsLoadingFileExport(false);
        brandService.getFile(res.toString(), typeFile);
      });
  };

  if (isLoading) return <BigLoader color="dark" />;

  return (
    <div className={brandsPageCn()}>
      <div
        data-title={
          'Все указанные расчетные данные представлены за период с 01.01.2023 по настоящее время'
        }
      >
        <Button color={ColorsButton.white} size={SizesButton.big} text="За все время" noDisabled />
      </div>
      <div>
        <InfoTable
          linkTemplate={linksUrl.brand}
          data={brandStat}
          columns={brandsInfoTableColumns}
        />
        <PieChart
          data={brandStat.map(
            (item: BrandDto, index: number) =>
              new ChartDataItem(
                item.id || index,
                item.name || '',
                item.revenue || -1,
                infoTableColors[index],
              ),
          )}
          errorText={'Не удалось загрузить данные'}
        />
      </div>

      <div className={brandsPageCn('title-panel')}>
        <p className="h1">Список брендов</p>
        <SearchInput
          value={filterBrands.name || ''}
          onChange={(v: any) => {
            setFilterBrands({ ...filterBrands, name: v });
          }}
          onEnterClick={onSearchInputEnter}
          placeholder="Поиск по брендам"
        />
      </div>
      <div className={brandsPageCn('buttons')}>
        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Фильтры"
          icon={<FilterIcon />}
          onClick={() => {
            setIsShowColumnsBar(false);
            setIsShowFilterBar(!isShowFilterBar);
          }}
        />
        <Export onClickExport={onClickExport} />
        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Выбрать колонки"
          icon={<ColumnsIcon />}
          onClick={() => {
            setIsShowFilterBar(false);
            setIsShowColumnsBar(!isShowColumnsBar);
          }}
        />
        <Pagination
          currentPageNumber={pageNumber}
          totalPagesCount={countBrands ? Math.ceil(countBrands / 50) : 1}
          onClick={onPagination}
        />
      </div>

      {tableColumns.length > 0 && (
        <>
          {isBrandsLoading ? (
            <TableLoad />
          ) : (
            <>
              <Table
                localStorageColumns={LocalStorageVariableName.brandColumn}
                columns={tableColumns}
                data={brands}
                onSort={onSort}
                idActiveSort={filterBrands.sortFieldId || 666}
                decs={filterBrands.desc || false}
              />
              <Pagination
                currentPageNumber={pageNumber}
                totalPagesCount={countBrands ? Math.ceil(countBrands / 50) : 1}
                onClick={onPagination}
              />
            </>
          )}

          <BarPanel
            title="Выбрать колонки"
            isShow={isShowColumnsBar}
            setIsShow={setIsShowColumnsBar}
          >
            <ColumnsBar
              localStorageColumns={LocalStorageVariableName.brandColumn}
              setColumns={setTableColumns}
              columns={tableColumns}
            />
          </BarPanel>
          <BarPanel title="Фильтры" isShow={isShowFilterBar} setIsShow={setIsShowFilterBar}>
            <FilterBar onApplyFilter={onApplyFilter} onResetFilter={onResetFilter}>
              <BrandFilter filterValues={filterBrands} setFilterValues={setFilterBrands} />
            </FilterBar>
          </BarPanel>
        </>
      )}
      {isLoadingFileExport && <LineLoading />}
    </div>
  );
};
