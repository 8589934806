import { FC } from 'react';
import { bemCN } from '../../configs/bem-classname';
import './simple-table.scss';
import { TableColumn, TypeColumn } from '../../view-models/table-column';
import { Button, ColorsButton, SizesButton } from '../button/button';
import { Link } from 'react-router-dom';
import { FavoriteTableItem } from '../table/favorite-table-item/favorite-table-item';
const simpleTableCn = bemCN('simple-table');

interface SimpleTableProps {
  columns: TableColumn[];
  data: any[];
}
export const SimpleTable: FC<SimpleTableProps> = ({ columns, data }) => {
  return (
    <table className={simpleTableCn()}>
      <thead>
        <tr>
          {columns.map((col: TableColumn) => (
            <th key={col.id}>{col.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any) => (
          <tr key={row.id}>
            {columns.map((col: TableColumn) => {
              if (col.typeColumn == TypeColumn.button)
                return (
                  <td key={row.id + col.id}>
                    <Button
                      icon={col.buttonIcon}
                      onClick={() => col.buttonOnClick && col.buttonOnClick(row.id)}
                      color={ColorsButton.transition}
                      size={SizesButton.small}
                    />
                  </td>
                );
              if (col.isLink)
                return (
                  <td key={row.id + col.id}>
                    <Link to={row[col.name]}>{row[col.name]}</Link>
                  </td>
                );
              else return <td key={row.id + col.id}>{row[col.name]}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
