import {
  ContainerStockByDateDto,
  FilterFieldDto,
  ProductCardStatisticsDto,
  ProductCardStatisticsFilterDto,
  ProductFilterDateDto,
  ProductFilterDownloadingDto,
  ProductFilterDto,
  SalesStatisticsDto,
  StockByDateDto,
  StocksStatisticsDto,
} from '../api/Api';
import { API } from '../configs/api';
import { linkColumnsName } from '../consts/link-column-names';
import { formatFullDate } from '../utils/date';
import { saveFile } from '../utils/saveFile';

import { TableColumn, TypeColumn } from '../view-models/table-column';
import { TypeFile } from '../view-models/type-files';

class ProductService {
  async getColumns() {
    try {
      let res = API.fields.getFieldsCreate();
      let result = (await res).data;
      if (result) {
        let columns: TableColumn[] = result
          .filter((fc: FilterFieldDto) => fc.id !== 4)
          .map((f: FilterFieldDto) => {
            if (f.id == 5 && f.name == 'Название') {
              return new TableColumn(
                666,
                f.siteFieldName || '',
                'Название              Топ',
                TypeColumn.string,
                f.defaultActive || false,
                undefined,
                true,
                linkColumnsName.includes(f.siteFieldName || ''),
                f.sort,
              );
            }

            return new TableColumn(
              f.id || -1,
              f.siteFieldName || '',
              f.name || '',
              TypeColumn.string,
              f.defaultActive || false,
              undefined,
              f.isSortable,
              linkColumnsName.includes(f.siteFieldName || ''),
              f.sort,
            );
          });
        return columns;
      } else return [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getProducts(filter: ProductFilterDto) {
    try {
      let res = API.productFilter.getProductsCreate({
        ...filter,
        sourceLink: window.location.href,
      });
      let result = (await res).data;
      // console.log(res);
      return result || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getProductsByDate(filter: ProductFilterDateDto) {
    try {
      let res = API.productFilter.getProductsByDateCreate({
        ...filter,
        sourceLink: window.location.href,
      });
      let result = (await res).data;
      // console.log(res);
      return result;
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getProduct(id: number) {
    try {
      let res = API.productCard.getProductCardCreate({ id });
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getProductsStats(filter: ProductFilterDto) {
    try {
      let res = API.productFilter.getProductStatisticsCreate(filter);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getProductStats(filter: ProductCardStatisticsFilterDto) {
    try {
      let res = API.productCard.getStocksStatisticsCreate(filter);
      let result = (await res).data;
      return {
        ...(result as ProductCardStatisticsDto),
        stocks: result.stocks?.map((s: StocksStatisticsDto) => {
          return { ...s, date: formatFullDate(s.date || '') };
        }),
      };
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getProductCount(filter: ProductFilterDto) {
    try {
      let res = API.productFilter.getCountProductsCreate(filter);
      let result = (await res).data;
      return Number(result);
    } catch (err: any) {
      console.log(err);
      return -1;
    }
  }

  async getStatSizeWirehouse(filter: ProductCardStatisticsFilterDto) {
    try {
      let res = API.productCard.getSalesByWhSzCreate(filter);
      let result = (await res).data;
      return result as SalesStatisticsDto;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async getStocksCard(filter: ProductCardStatisticsFilterDto) {
    try {
      let res = API.productCard.getStocksCreate(filter);
      let result = (await res).data;
      return result as ContainerStockByDateDto;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  // async getSeasonalityAnalysis(filter: ProductCardStatisticsFilterDto) {
  //   try {
  //     let res = API.productCard.getStocksCreate(filter);
  //     let result = (await res).data;
  //     return result as ContainerStockByDateDto;
  //   } catch (err: any) {
  //     console.log(err);
  //     return {};
  //   }
  // }

  async startExportFile(filter: ProductFilterDto, typeFile: TypeFile, fieldsIds: number[]) {
    try {
      let fileFilter: ProductFilterDownloadingDto = {
        typeFile: Number(typeFile),
        fieldIds: fieldsIds,
        ...filter,
      };
      let res = API.productFilter.createDownloadFileCreate(fileFilter);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }

  async startExportFileByDate(
    filter: ProductFilterDateDto,
    typeFile: TypeFile,
    fieldsIds: number[],
  ) {
    try {
      let fileFilter: ProductFilterDownloadingDto = {
        typeFile: Number(typeFile),
        fieldIds: fieldsIds,
        ...filter,
      };
      let res = API.productFilter.createDownloadFileByDateCreate(fileFilter);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }

  async getFile(idFile: string, fileType: TypeFile) {
    try {
      let res = API.productFilter.getFileByIdList({ id: idFile });
      let result = await (await res).blob();
      saveFile(result, TypeFile[fileType]);
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }
}

export const productsService: ProductService = new ProductService();
