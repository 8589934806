/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { linksUrl } from "../consts/linksUrl";

export interface AddApiKeyRequestDto {
  /** Имя ключа. */
  name?: string | null;
  /** Apikey. */
  apiKey?: string | null;
}

export interface AddApiKeyResponseDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format date-time */
  expiryDateTime?: string;
  /** @format uuid */
  ownerId?: string;
  scopeFlags?: string[] | null;
}

export interface AdvertCampaign {
  /** @format int32 */
  campaignId?: number;
  /** @format int32 */
  campaignType?: number;
  /** @format uuid */
  ownerId?: string;
  owner?: SiteUser;
  autobidderEnabled?: boolean;
  /** @format int32 */
  maxCpm?: number;
  /** @format int32 */
  targetPlace?: number;
  /** @format int32 */
  minPlace?: number;
  /** @format int32 */
  outBidAmount?: number;
  hadError?: boolean;
  lastError?: string | null;
}

/** @format int32 */
export enum AdvertCampaignStatusEnum {
  Value4 = 4,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value11 = 11,
  Value1 = -1,
}

/** @format int32 */
export enum AdvertCampaignTypeEnum {
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export interface ApiKey {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format uuid */
  ownerId?: string;
  owner?: SiteUser;
  /** @format int32 */
  cabinetId?: number;
  key?: string | null;
  scopeFlags?: ApiKeyScopesFlagsEnum;
  /** @format date-time */
  expiryDateTime?: string;
}

/** @format int32 */
export enum ApiKeyScopesFlagsEnum {
  Value1 = 1,
  Value2 = 2,
  Value4 = 4,
  Value8 = 8,
  Value16 = 16,
  Value32 = 32,
  Value64 = 64,
  Value128 = 128,
  Value256 = 256,
  Value512 = 512,
  Value1073741824 = 1073741824,
}

export interface AutoCampaignDto {
  /** @format int64 */
  id?: number;
  /** Название кампании */
  name?: string | null;
  type?: AdvertCampaignTypeEnum;
  /** Название типа кампании */
  typeName?: string | null;
  /**
   * Время окончания кампании
   * @format date-time
   */
  endTime?: string;
  /**
   * Время изменения кампании
   * @format date-time
   */
  changeTime?: string;
  /**
   * Время создания кампании
   * @format date-time
   */
  createTime?: string;
  /**
   * Время старта кампании
   * @format date-time
   */
  startTime?: string;
  status?: AdvertCampaignStatusEnum;
  /** Название статуса кампании */
  statusName?: string | null;
  /** @format int32 */
  categoryId?: number;
  categoryName?: string | null;
  /** @format int32 */
  cpm?: number;
  /** @format int32 */
  countProducts?: number;
  /** @format int32 */
  budget?: number;
  fromCache?: boolean;
  products?: AutoCampaignProductDto[] | null;
}

export interface AutoCampaignProductDto {
  /** @format int32 */
  id?: number;
  /** Название товара */
  name?: string | null;
  /**
   * Id категории
   * @format int32
   */
  subjectId?: number;
  /** Ссылка на картинку */
  imageUrl?: string | null;
  /** В наличии ли товар */
  inStock?: boolean;
  /** @format int32 */
  cpm?: number;
}

export interface BalanceResponseDto {
  /** @format int32 */
  balance?: number;
  /** @format int32 */
  net?: number;
  /** @format int32 */
  bonus?: number;
}

/** Справочник брендов. */
export interface BrandDto {
  /** @format int32 */
  id?: number;
  /** Название. */
  name?: string | null;
  /**
   * Количество товаров бренда.
   * @format int32
   */
  countProducts?: number;
  /**
   * Количество продавцов, продающих бренд.
   * @format int32
   */
  countSalers?: number;
  /**
   * Количество продаж.
   * @format int64
   */
  countSales?: number;
  /**
   * Выручка.
   * @format int64
   */
  revenue?: number;
  /**
   * Средняя цена.
   * @format int32
   */
  priceAvg?: number;
  /**
   * Дневная выручка.
   * @format int32
   */
  dailyRevenueAvg?: number;
  /**
   * Упущенная выручка.
   * @format int64
   */
  loseRevenue?: number;
  /**
   * Упущенная выручка процент.
   * @format double
   */
  loseRevenueProcent?: number;
  /**
   * Рейтинг.
   * @format float
   */
  rating?: number;
}

export interface BrandFieldDto {
  /** @format int32 */
  id?: number;
  /** Lable для таблицы. */
  name?: string | null;
  /** Включен по умолчанию. */
  defaultActive?: boolean;
  /** Ключ для сравнения с полями ProductDto. */
  siteFieldName?: string | null;
  /** Возмоможность соритровки. */
  isSortable?: boolean;
  /**
   * Порядок сортировки столбцов.
   * @format int32
   */
  sort?: number;
}

export interface BrandFilterDownloadingDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
  /** Название. */
  name?: string | null;
  /**
   * Количество продаж от.
   * @format int64
   */
  countSaleFrom?: number | null;
  /**
   * Количество продаж до.
   * @format int64
   */
  countSaleTo?: number | null;
  /**
   * Количество продаж от.
   * @format int32
   */
  countSalerFrom?: number | null;
  /**
   * Количество продаж до.
   * @format int32
   */
  countSalerTo?: number | null;
  /**
   * Количество продуктов от.
   * @format int32
   */
  countProductFrom?: number | null;
  /**
   * Количество продуктов до.
   * @format int32
   */
  countProductTo?: number | null;
  /**
   * Рейтинг от.
   * @format float
   */
  raitingFrom?: number | null;
  /**
   * Рейтинг до.
   * @format float
   */
  raitingTo?: number | null;
  period?: PeriodDto;
  sourceLink?: string | null;
  /** Активен. */
  active?: boolean | null;
  fieldsIds?: number[] | null;
  typeFile?: DownloadingType;
}

export interface BrandFilterDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
  /** Название. */
  name?: string | null;
  /**
   * Количество продаж от.
   * @format int64
   */
  countSaleFrom?: number | null;
  /**
   * Количество продаж до.
   * @format int64
   */
  countSaleTo?: number | null;
  /**
   * Количество продаж от.
   * @format int32
   */
  countSalerFrom?: number | null;
  /**
   * Количество продаж до.
   * @format int32
   */
  countSalerTo?: number | null;
  /**
   * Количество продуктов от.
   * @format int32
   */
  countProductFrom?: number | null;
  /**
   * Количество продуктов до.
   * @format int32
   */
  countProductTo?: number | null;
  /**
   * Рейтинг от.
   * @format float
   */
  raitingFrom?: number | null;
  /**
   * Рейтинг до.
   * @format float
   */
  raitingTo?: number | null;
  period?: PeriodDto;
  sourceLink?: string | null;
  /** Активен. */
  active?: boolean | null;
}

export interface BrandStatisticsDto {
  /** @format int64 */
  revenue?: number;
  /** @format int32 */
  sale?: number;
  /** @format int64 */
  lostRevenue?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int32 */
  sellerCount?: number;
}

export interface CampaignDto {
  /** @format int64 */
  id?: number;
  /** Название кампании */
  name?: string | null;
  type?: AdvertCampaignTypeEnum;
  /** Название типа кампании */
  typeName?: string | null;
  /**
   * Время окончания кампании
   * @format date-time
   */
  changeTime?: string;
  /**
   * Время создания кампании
   * @format date-time
   */
  createTime?: string;
  status?: AdvertCampaignStatusEnum;
  /** Название статуса кампании */
  statusName?: string | null;
}

/** Справочник брендов. */
export interface CatalogDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format int32 */
  productsCount?: number;
  categories?: CategoryDto[] | null;
}

export interface CatalogFilterDto {
  themes?: number[] | null;
  /** @format date */
  from?: string;
  /** @format date */
  to?: string;
}

export interface CatalogStatisticsByDate {
  /**
   * Средняя цена.
   * @format int32
   */
  averagePrice?: number;
  /**
   * Средния выручка.
   * @format int32
   */
  averageRevenue?: number;
  /**
   * Средний процент.
   * @format int32
   */
  buyoutPercent?: number | null;
  /**
   * Выручка.
   * @format int64
   */
  revenue?: number;
  /**
   * Упущенная прибыль.
   * @format int64
   */
  lostRevenue?: number;
}

export interface CatalogStocksDto {
  /** @format date */
  date?: string;
  /** @format int32 */
  productsCount?: number;
  /** @format int64 */
  revenue?: number;
  /** @format int64 */
  sales?: number;
  /** @format int64 */
  sellerCount?: number;
}

/** Справочник категорий. */
export interface CategoryDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface CategoryStatisticsDto {
  /**
   * Каталог статистики.
   * @format int32
   */
  catalogId?: number;
  /** Родительская категория - каталог. */
  nameCategory?: string | null;
  /**
   * Количество артикулов.
   * @format int32
   */
  countArticuls?: number;
  /**
   * Количество артикулов с продажами.
   * @format int32
   */
  countArticulWithSales?: number;
  /**
   * Количество брендов.
   * @format int32
   */
  countBrends?: number;
  /**
   * Количество брендов с продажами.
   * @format int32
   */
  countBrendsWithSale?: number;
  /**
   * Количество поставщиков.
   * @format int32
   */
  countSuppliers?: number;
  /**
   * Количество поставщиков с продажами.
   * @format int32
   */
  countSuppliersWithSale?: number;
}

export interface ChanagePasswordDto {
  /** Старый пароль. */
  oldPassword?: string | null;
  /** Новый пароль. */
  newPassword?: string | null;
}

export interface ClickCampaignDto {
  /** Дата */
  date?: string | null;
  /**
   * Суммарное количество кликов (по всем кампаниям)
   * @format int32
   */
  sumClicks?: number;
}

export interface ContainerStockByDateDto {
  warehouses?: WarehouseDto[] | null;
  stocks?: StockByDateDto[] | null;
}

export interface CostCampaignDto {
  /**
   * Id рекламной кампании
   * @format int32
   */
  idCampaign?: number;
  /**
   * Значение затрат на рекламную кампанию
   * @format double
   */
  costs?: number;
}

/** Справочник стран. */
export interface CountryDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface CpcCampaignDto {
  /**
   * Id рекламной кампании
   * @format int32
   */
  idCampaign?: number;
  /**
   * Средняя стоимость клика
   * @format double
   */
  cpc?: number;
}

export interface CpmResponseDto {
  /** @format int32 */
  cpm?: number;
  /** @format int32 */
  count?: number;
}

export interface CreateAutoCampaignRequestDto {
  /** Название кампании (max. 128 символов). */
  name?: string | null;
  /**
   * ID предмета, для которого создается кампания.
   * @format int32
   */
  categoryId?: number;
  /**
   * Сумма пополнения.
   * @format int32
   */
  budget?: number;
  /**
   * Тип списания.
   * @format int32
   */
  budgetType?: number;
  /** Запуск кампании. */
  onPause?: boolean;
  /** Массив артикулов WB. */
  productIds?: number[] | null;
  /**
   * Ставка.
   * @format double
   */
  cpm?: number;
}

export interface CreateSeacatCampaignRequestDto {
  /** Название кампании. */
  campaignName?: string | null;
  /** Номенклатуры для кампании. Массив артикулов WB. */
  nms?: number[] | null;
}

export interface CtrCampaignDto {
  /**
   * Id рекламной кампании
   * @format int32
   */
  idCampaign?: number;
  /**
   * Показатель кликабельности кампании за весь период
   * @format double
   */
  ctr?: number;
}

export interface CurrentSubscriptionDto {
  name?: string | null;
  /** @format date */
  expiryDate?: string | null;
}

export interface DepositRequestDto {
  /** @format int32 */
  sum?: number;
  /** @format int32 */
  type?: number;
  return?: boolean;
}

export interface DepositResponseDto {
  /** @format int32 */
  total?: number;
}

/** @format int32 */
export enum DownloadingType {
  Value0 = 0,
  Value1 = 1,
}

export interface FavoriteDownloadDto {
  /** Id полей. */
  fieldIds: number[] | null;
  typeFile?: DownloadingType;
}

export interface FeedbackFormDto {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  message?: string | null;
  /** @format int32 */
  specialty?: number;
}

export interface FilterFieldDto {
  /** @format int32 */
  id?: number;
  /** Lable для таблицы. */
  name?: string | null;
  /** Включен по умолчанию. */
  defaultActive?: boolean;
  /** Ключ для сравнения с полями ProductDto. */
  siteFieldName?: string | null;
  /** Возмоможность соритровки. */
  isSortable?: boolean;
  /**
   * Порядок столбцов.
   * @format int32
   */
  sort?: number;
}

/** Навигация по фильтру. */
export interface FilterNavigationDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
}

export interface FilterTextSearchDto {
  /** Паттерн поиска. */
  pattern?: string | null;
  /** Id ранее выбранных каталогов. */
  selectedIds?: number[] | null;
  /** Id категории. */
  categoryIds?: number[] | null;
  /** Id под категории. */
  subCategoryIds?: number[] | null;
  /** Id */
  brandCategoryIds?: number[] | null;
  /** Id */
  countryCategoryIds?: number[] | null;
}

export interface GetApiKeysByOwnerResponseDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format date-time */
  expiryDateTime?: string;
  scopeFlags?: string[] | null;
  /** @format int32 */
  cabinetId?: number;
}

export interface GetCampaignStatisticsDto {
  /**
   * Дата начала периода
   * @format date
   */
  dateFrom?: string;
  /**
   * Дата конца периода
   * @format date
   */
  dateTo?: string;
}

export interface HistoryDto {
  /** @format date-time */
  dateTime?: string;
  name?: string | null;
  type?: string | null;
  url?: string | null;
}

export interface HistoryOffsetDto {
  /** @format int32 */
  offset?: number;
  /** @format int32 */
  count?: number;
}

export interface InitPaymentDto {
  url?: string | null;
}

/** Цвета. */
export interface LabelColorDto {
  /** @format int32 */
  id?: number;
  /** Цвет в css стиле. */
  color?: string | null;
}

/** Метка в избранном. */
export interface LabelDto {
  /** @format int32 */
  id?: number;
  /**
   * Название метки.
   * @pattern ^[a-zA-Zа-яёА-ЯЁ\.0-9\s-_]{1,50}$
   */
  name?: string | null;
  /**
   * Id цвета LabelColor.
   * @format int32
   */
  colorId?: number;
}

/** Продукт в избранном. Используется для передачи строк продуктов. */
export interface LabelProductContentDto {
  /**
   * ИД.
   * @format int32
   */
  id?: number;
  /**
   * Артикул.
   * @format int32
   */
  articul?: number;
  /** Бренд. */
  brandName?: string | null;
  /**
   * Id бренда.
   * @format int32
   */
  brandId?: number;
  /** Категория товара. */
  category?: string | null;
  /** Подкатегория. */
  subCategory?: string | null;
  /** Название. */
  name?: string | null;
  /**
   * Цена до скидок.
   * @format double
   */
  priceTotal?: number;
  /**
   * Цена после скидок.
   * @format double
   */
  priceDiscount?: number;
  /**
   * Скидка.
   * @format double
   */
  discount?: number;
  /**
   * Ид продавца.
   * @format int32
   */
  salerId?: number;
  /** Продавец-имя. */
  salerName?: string | null;
  /**
   * Дробный рейтинг.
   * @format double
   */
  reviewRating?: number;
  /**
   * Рейтинг (товара).
   * @format int32
   */
  rating?: number;
  /**
   * Количество отзывов по товару.
   * @format int32
   */
  feedBacks?: number;
  /**
   * Количество продаж товара.
   * @format int64
   */
  countSale?: number;
  /**
   * Дата первого отзыва.
   * @format date-time
   */
  firstFeedback?: string | null;
  /**
   * Дата создания карточки.
   * @format date-time
   */
  createdDate?: string | null;
  /** Страна производитель. */
  countryMaker?: string | null;
  /** Ссылка на товар. */
  link?: string | null;
  /** Пол. */
  gender?: string | null;
  /** ТНВЭД. */
  tnvd?: string | null;
  /** Фото. */
  photoUrl?: string | null;
  /**
   * Количество фото.
   * @format int32
   */
  photoCount?: number;
  /**
   * Возвраты по товару (процент выкупа).
   * @format double
   */
  redemptionPercentage?: number | null;
  /** Цвета. */
  colors?: string | null;
  /** Размеры. */
  sizes?: string | null;
  /** Описание. */
  description?: string | null;
  /** Комплектация. */
  options?: string | null;
  hasVideo?: string | null;
  /** Купили более. */
  roundedSales?: string | null;
  /** Акция. */
  promotion?: string | null;
  /** Новинка. */
  isNew?: boolean | null;
  /**
   * Кол-во категорий.
   * @format int32
   */
  countCatigories?: number;
  /**
   * Минимальная цена за полгода.
   * @format int32
   */
  minPrice?: number | null;
  /**
   * Максимальная цена за полгода.
   * @format int32
   */
  maxPrice?: number | null;
  /**
   * Средняя цена.
   * @format int32
   */
  avgPrice?: number | null;
  /**
   * Выручка.
   * @format int64
   */
  revenue?: number;
  /**
   * Выручка в день.
   * @format double
   */
  avgRevenuePerDay?: number;
  /**
   * Упущенная выручка, руб.
   * @format double
   */
  loseRevenue?: number;
  /**
   * Упущенная выручка, %.
   * @format double
   */
  loseRevenueProcent?: number;
  /**
   * Id категории.
   * @format int32
   */
  categoryId?: number;
  /**
   * Ид подкатегории.
   * @format int32
   */
  subCategoryId?: number;
  /**
   * Текущий остаток.
   * @format int32
   */
  currentStock?: number | null;
  /**
   * Дни наличия.
   * @format int32
   */
  availabilityDays?: number;
  /**
   * Id темы.
   * @format int32
   */
  themeId?: number;
  /** Название темы. */
  themeName?: string | null;
  path?: PathDto[] | null;
  /**
   * Метка.
   * @format int32
   */
  labelId?: number | null;
}

/** Метка продукта. */
export interface LabelProductDto {
  /**
   * Id метки.
   * @format int32
   */
  lableId?: number | null;
  /**
   * Id продукта.
   * @format int32
   */
  productId?: number;
  /**
   * Id маркетплейса.
   * @format int32
   */
  marketplaceId?: number;
}

/** @format int32 */
export enum LogLevel {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface MenuDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  isParent?: boolean;
  isDenyLink?: boolean;
  /** @format int32 */
  productCount?: number | null;
  childs?: MenuDto[] | null;
}

export interface NotificationPaymentRequest {
  terminalKey?: string | null;
  /** @format int32 */
  amount?: number;
  orderId?: string | null;
  success?: boolean;
  status?: string | null;
  /** @format int64 */
  paymentId?: number;
  errorCode?: string | null;
  message?: string | null;
  details?: string | null;
  /** @format int32 */
  rebillId?: number | null;
  /** @format int32 */
  cardId?: number;
  pan?: string | null;
  expDate?: string | null;
  token?: string | null;
  data?: any;
}

export interface Order {
  /** @format int32 */
  id?: number;
  /** @format uuid */
  userId?: string;
  status?: OrderStatusEnum;
  /** @format int32 */
  amount?: number;
  statusName?: string | null;
  user?: SiteUser;
  /** @format int32 */
  itemId?: number;
  item?: SellableItem;
  /** @format date-time */
  createdTime?: string | null;
  /** @format date-time */
  completionTime?: string | null;
}

export interface OrderCampaignDto {
  /**
   * Id рекламной кампании
   * @format int32
   */
  idCampaign?: number;
  /**
   * Количество заказов
   * @format int32
   */
  ordersCount?: number;
}

export interface OrderHistoryDto {
  /** @format date-time */
  orderDate?: string | null;
  subscriptionName?: string | null;
  status?: string | null;
  paymentMethod?: string | null;
  /** @format int32 */
  amount?: number;
}

/** @format int32 */
export enum OrderStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value11 = -1,
}

export interface PageCountDto {
  /** @format int32 */
  count?: number;
}

export interface PathDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  isSubject?: boolean;
}

/** @format int32 */
export enum PeriodDto {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface PhotoDto {
  base64?: string | null;
}

export interface ProductCardDto {
  /** @format int32 */
  id?: number;
  /** Название. */
  name?: string | null;
  /**
   * Количество отзывов.
   * @format int32
   */
  feedBackCount?: number | null;
  /**
   * Рейтинг.
   * @format double
   */
  raiting?: number | null;
  /** Артикул. */
  articul?: string | null;
  /**
   * Дата появления на сервере.
   * @format date
   */
  dateCreate?: string;
  /** Категория. */
  category?: string | null;
  /** Название бренда. */
  brandName?: string | null;
  /**
   * Id бренда.
   * @format int32
   */
  brandId?: number | null;
  /** Имя продавца. */
  salerName?: string | null;
  /**
   * Id продавца.
   * @format int32
   */
  salerId?: number | null;
  /**
   * Цена.
   * @format double
   */
  priceTotal?: number;
  /**
   * Цена без СПП.
   * @format double
   */
  price?: number;
  /**
   * Скидка.
   * @format double
   */
  discount?: number;
  /**
   * Сумма продажи.
   * @format double
   */
  priceSale?: number;
  /**
   * Выкуп.
   * @format int32
   */
  buyout?: number;
  /**
   * Персональная скидка
   * @format int32
   */
  personalDiscount?: number;
  /** Комиссия МП. */
  commision?: string | null;
  /** Ссылка на фото товара. */
  imageUrl?: string | null;
  /**
   * Id продовца.
   * @format int32
   */
  sellerId?: number;
  /**
   * Id категории.
   * @format int32
   */
  categoryId?: number;
  /**
   * Id подкатегории.
   * @format int32
   */
  subCategoryId?: number;
  /** Товар находится в избранном. */
  isFavorite?: boolean;
  path?: PathDto[] | null;
}

export interface ProductCardStatisticsDto {
  /**
   * Выручка.
   * @format int64
   */
  revenue?: number;
  /**
   * Продажи.
   * @format int64
   */
  sales?: number;
  /**
   * Дней отсутствовал.
   * @format int64
   */
  outOfStokcs?: number;
  /**
   * Средния выручка в день.
   * @format double
   */
  revenuePerDay?: number;
  /**
   * Средние число продаж в день.
   * @format double
   */
  salesPerDay?: number;
  /**
   * Упущенная выручка в день.
   * @format int64
   */
  loseRevenue?: number;
  /** Остатки. */
  stocks?: StocksStatisticsDto[] | null;
}

export interface ProductCardStatisticsFilterDto {
  /**
   * Id продукта.
   * @format int32
   */
  id?: number;
  /**
   * Дата начала.
   * @format date
   */
  dateFrom?: string;
  /**
   * Дата к.
   * @format date
   */
  dateTo?: string;
}

export interface ProductDto {
  /**
   * ИД.
   * @format int32
   */
  id?: number;
  /**
   * Артикул.
   * @format int32
   */
  articul?: number;
  /** Бренд. */
  brandName?: string | null;
  /**
   * Id бренда.
   * @format int32
   */
  brandId?: number;
  /** Категория товара. */
  category?: string | null;
  /** Подкатегория. */
  subCategory?: string | null;
  /** Название. */
  name?: string | null;
  /**
   * Цена до скидок.
   * @format double
   */
  priceTotal?: number;
  /**
   * Цена после скидок.
   * @format double
   */
  priceDiscount?: number;
  /**
   * Скидка.
   * @format double
   */
  discount?: number;
  /**
   * Ид продавца.
   * @format int32
   */
  salerId?: number;
  /** Продавец-имя. */
  salerName?: string | null;
  /**
   * Дробный рейтинг.
   * @format double
   */
  reviewRating?: number;
  /**
   * Рейтинг (товара).
   * @format int32
   */
  rating?: number;
  /**
   * Количество отзывов по товару.
   * @format int32
   */
  feedBacks?: number;
  /**
   * Количество продаж товара.
   * @format int64
   */
  countSale?: number;
  /**
   * Дата первого отзыва.
   * @format date-time
   */
  firstFeedback?: string | null;
  /**
   * Дата создания карточки.
   * @format date-time
   */
  createdDate?: string | null;
  /** Страна производитель. */
  countryMaker?: string | null;
  /** Ссылка на товар. */
  link?: string | null;
  /** Пол. */
  gender?: string | null;
  /** ТНВЭД. */
  tnvd?: string | null;
  /** Фото. */
  photoUrl?: string | null;
  /**
   * Количество фото.
   * @format int32
   */
  photoCount?: number;
  /**
   * Возвраты по товару (процент выкупа).
   * @format double
   */
  redemptionPercentage?: number | null;
  /** Цвета. */
  colors?: string | null;
  /** Размеры. */
  sizes?: string | null;
  /** Описание. */
  description?: string | null;
  /** Комплектация. */
  options?: string | null;
  hasVideo?: string | null;
  /** Купили более. */
  roundedSales?: string | null;
  /** Акция. */
  promotion?: string | null;
  /** Новинка. */
  isNew?: boolean | null;
  /**
   * Кол-во категорий.
   * @format int32
   */
  countCatigories?: number;
  /**
   * Минимальная цена за полгода.
   * @format int32
   */
  minPrice?: number | null;
  /**
   * Максимальная цена за полгода.
   * @format int32
   */
  maxPrice?: number | null;
  /**
   * Средняя цена.
   * @format int32
   */
  avgPrice?: number | null;
  /**
   * Выручка.
   * @format int64
   */
  revenue?: number;
  /**
   * Выручка в день.
   * @format double
   */
  avgRevenuePerDay?: number;
  /**
   * Упущенная выручка, руб.
   * @format double
   */
  loseRevenue?: number;
  /**
   * Упущенная выручка, %.
   * @format double
   */
  loseRevenueProcent?: number;
  /**
   * Id категории.
   * @format int32
   */
  categoryId?: number;
  /**
   * Ид подкатегории.
   * @format int32
   */
  subCategoryId?: number;
  /**
   * Текущий остаток.
   * @format int32
   */
  currentStock?: number | null;
  /**
   * Дни наличия.
   * @format int32
   */
  availabilityDays?: number;
  /**
   * Id темы.
   * @format int32
   */
  themeId?: number;
  /** Название темы. */
  themeName?: string | null;
  path?: PathDto[] | null;
}

export interface ProductFilterDateDownloadingDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
  /** Название. */
  name?: string | null;
  /**
   * Цена от.
   * @format int32
   */
  priceFrom?: number | null;
  /**
   * Цена до.
   * @format int32
   */
  priceTo?: number | null;
  /**
   * Цена со скидкой от.
   * @format int32
   */
  oldPriceFrom?: number | null;
  /**
   * Цена со скидкой до.
   * @format int32
   */
  oldPriceTo?: number | null;
  /**
   * Скидка от.
   * @format int32
   */
  discountFrom?: number | null;
  /**
   * Скидка до.
   * @format int32
   */
  discountTo?: number | null;
  /**
   * Текущий остаток от.
   * @format int32
   */
  balanceFrom?: number | null;
  /**
   * Текущий остаток до.
   * @format int32
   */
  balanceTo?: number | null;
  /**
   * Продажи от.
   * @format int32
   */
  salesFrom?: number | null;
  /**
   * Продажи до.
   * @format int32
   */
  salesTo?: number | null;
  /**
   * Выручка от.
   * @format int64
   */
  revenueFrom?: number | null;
  /**
   * Выручка до.
   * @format int64
   */
  revenueTo?: number | null;
  /**
   * Количество отзывов от.
   * @format int32
   */
  feedbaksFrom?: number | null;
  /**
   * Количество отзывов до.
   * @format int32
   */
  feedbaksTo?: number | null;
  /**
   * Рейтинг от.
   * @format float
   */
  raitingFrom?: number | null;
  /**
   * Рейтинг до.
   * @format float
   */
  raitingTo?: number | null;
  /**
   * Количество категорий от.
   * @format int32
   */
  countCategoryFrom?: number | null;
  /**
   * Количество категорий до.
   * @format int32
   */
  countCategoryTo?: number | null;
  /** Техническая категория. */
  categoryIds?: number[] | null;
  /** Бренды. */
  brandIds?: number[] | null;
  /** Страны. */
  countryIds?: number[] | null;
  /** Техническая подкатегория. */
  subCategoriesIds?: number[] | null;
  period?: PeriodDto;
  /**
   * Id Поставщика.
   * @format int32
   */
  supplierId?: number | null;
  sourceLink?: string | null;
  /** Тема. */
  themeCategories?: number[] | null;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateTo?: string;
  withSales?: boolean | null;
  /** Id полей. */
  fieldIds: number[] | null;
  typeFile?: DownloadingType;
}

export interface ProductFilterDateDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
  /** Название. */
  name?: string | null;
  /**
   * Цена от.
   * @format int32
   */
  priceFrom?: number | null;
  /**
   * Цена до.
   * @format int32
   */
  priceTo?: number | null;
  /**
   * Цена со скидкой от.
   * @format int32
   */
  oldPriceFrom?: number | null;
  /**
   * Цена со скидкой до.
   * @format int32
   */
  oldPriceTo?: number | null;
  /**
   * Скидка от.
   * @format int32
   */
  discountFrom?: number | null;
  /**
   * Скидка до.
   * @format int32
   */
  discountTo?: number | null;
  /**
   * Текущий остаток от.
   * @format int32
   */
  balanceFrom?: number | null;
  /**
   * Текущий остаток до.
   * @format int32
   */
  balanceTo?: number | null;
  /**
   * Продажи от.
   * @format int32
   */
  salesFrom?: number | null;
  /**
   * Продажи до.
   * @format int32
   */
  salesTo?: number | null;
  /**
   * Выручка от.
   * @format int64
   */
  revenueFrom?: number | null;
  /**
   * Выручка до.
   * @format int64
   */
  revenueTo?: number | null;
  /**
   * Количество отзывов от.
   * @format int32
   */
  feedbaksFrom?: number | null;
  /**
   * Количество отзывов до.
   * @format int32
   */
  feedbaksTo?: number | null;
  /**
   * Рейтинг от.
   * @format float
   */
  raitingFrom?: number | null;
  /**
   * Рейтинг до.
   * @format float
   */
  raitingTo?: number | null;
  /**
   * Количество категорий от.
   * @format int32
   */
  countCategoryFrom?: number | null;
  /**
   * Количество категорий до.
   * @format int32
   */
  countCategoryTo?: number | null;
  /** Техническая категория. */
  categoryIds?: number[] | null;
  /** Бренды. */
  brandIds?: number[] | null;
  /** Страны. */
  countryIds?: number[] | null;
  /** Техническая подкатегория. */
  subCategoriesIds?: number[] | null;
  period?: PeriodDto;
  /**
   * Id Поставщика.
   * @format int32
   */
  supplierId?: number | null;
  sourceLink?: string | null;
  /** Тема. */
  themeCategories?: number[] | null;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateTo?: string;
  withSales?: boolean | null;
}

/** Фильтр выгрузки. */
export interface ProductFilterDownloadingDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
  /** Название. */
  name?: string | null;
  /**
   * Цена от.
   * @format int32
   */
  priceFrom?: number | null;
  /**
   * Цена до.
   * @format int32
   */
  priceTo?: number | null;
  /**
   * Цена со скидкой от.
   * @format int32
   */
  oldPriceFrom?: number | null;
  /**
   * Цена со скидкой до.
   * @format int32
   */
  oldPriceTo?: number | null;
  /**
   * Скидка от.
   * @format int32
   */
  discountFrom?: number | null;
  /**
   * Скидка до.
   * @format int32
   */
  discountTo?: number | null;
  /**
   * Текущий остаток от.
   * @format int32
   */
  balanceFrom?: number | null;
  /**
   * Текущий остаток до.
   * @format int32
   */
  balanceTo?: number | null;
  /**
   * Продажи от.
   * @format int32
   */
  salesFrom?: number | null;
  /**
   * Продажи до.
   * @format int32
   */
  salesTo?: number | null;
  /**
   * Выручка от.
   * @format int64
   */
  revenueFrom?: number | null;
  /**
   * Выручка до.
   * @format int64
   */
  revenueTo?: number | null;
  /**
   * Количество отзывов от.
   * @format int32
   */
  feedbaksFrom?: number | null;
  /**
   * Количество отзывов до.
   * @format int32
   */
  feedbaksTo?: number | null;
  /**
   * Рейтинг от.
   * @format float
   */
  raitingFrom?: number | null;
  /**
   * Рейтинг до.
   * @format float
   */
  raitingTo?: number | null;
  /**
   * Количество категорий от.
   * @format int32
   */
  countCategoryFrom?: number | null;
  /**
   * Количество категорий до.
   * @format int32
   */
  countCategoryTo?: number | null;
  /** Техническая категория. */
  categoryIds?: number[] | null;
  /** Бренды. */
  brandIds?: number[] | null;
  /** Страны. */
  countryIds?: number[] | null;
  /** Техническая подкатегория. */
  subCategoriesIds?: number[] | null;
  period?: PeriodDto;
  /**
   * Id Поставщика.
   * @format int32
   */
  supplierId?: number | null;
  sourceLink?: string | null;
  /** Тема. */
  themeCategories?: number[] | null;
  /** Id полей. */
  fieldIds: number[] | null;
  typeFile?: DownloadingType;
}

/** Фильтр по продуктам. */
export interface ProductFilterDto {
  /** Тип сортировки true = Desc, false = Asc, null = Asc. */
  desc?: boolean | null;
  /**
   * Лимит строк.
   * @format int32
   */
  limit?: number | null;
  /**
   * Cмещение в количестве строк.
   * @format int32
   */
  offset?: number | null;
  /**
   * Id поля для сортировки.
   * @format int32
   */
  sortFieldId?: number | null;
  /** Название. */
  name?: string | null;
  /**
   * Цена от.
   * @format int32
   */
  priceFrom?: number | null;
  /**
   * Цена до.
   * @format int32
   */
  priceTo?: number | null;
  /**
   * Цена со скидкой от.
   * @format int32
   */
  oldPriceFrom?: number | null;
  /**
   * Цена со скидкой до.
   * @format int32
   */
  oldPriceTo?: number | null;
  /**
   * Скидка от.
   * @format int32
   */
  discountFrom?: number | null;
  /**
   * Скидка до.
   * @format int32
   */
  discountTo?: number | null;
  /**
   * Текущий остаток от.
   * @format int32
   */
  balanceFrom?: number | null;
  /**
   * Текущий остаток до.
   * @format int32
   */
  balanceTo?: number | null;
  /**
   * Продажи от.
   * @format int32
   */
  salesFrom?: number | null;
  /**
   * Продажи до.
   * @format int32
   */
  salesTo?: number | null;
  /**
   * Выручка от.
   * @format int64
   */
  revenueFrom?: number | null;
  /**
   * Выручка до.
   * @format int64
   */
  revenueTo?: number | null;
  /**
   * Количество отзывов от.
   * @format int32
   */
  feedbaksFrom?: number | null;
  /**
   * Количество отзывов до.
   * @format int32
   */
  feedbaksTo?: number | null;
  /**
   * Рейтинг от.
   * @format float
   */
  raitingFrom?: number | null;
  /**
   * Рейтинг до.
   * @format float
   */
  raitingTo?: number | null;
  /**
   * Количество категорий от.
   * @format int32
   */
  countCategoryFrom?: number | null;
  /**
   * Количество категорий до.
   * @format int32
   */
  countCategoryTo?: number | null;
  /** Техническая категория. */
  categoryIds?: number[] | null;
  /** Бренды. */
  brandIds?: number[] | null;
  /** Страны. */
  countryIds?: number[] | null;
  /** Техническая подкатегория. */
  subCategoriesIds?: number[] | null;
  period?: PeriodDto;
  /**
   * Id Поставщика.
   * @format int32
   */
  supplierId?: number | null;
  sourceLink?: string | null;
  /** Тема. */
  themeCategories?: number[] | null;
}

export interface ProductStatisticsDto {
  /** @format int64 */
  allCount?: number;
  /** @format int64 */
  brands?: number;
  /** @format int64 */
  sales?: number;
  /** @format int64 */
  suppliers?: number;
}

export interface ProfileDto {
  /** Имя. */
  name?: string | null;
  /** Телефон. */
  phoneNumber?: string | null;
  /** Почта. */
  email?: string | null;
}

export interface RecoverPasswordDto {
  /**
   * Ключ.
   * @format uuid
   */
  key?: string;
  /** Новый пароль. */
  password?: string | null;
}

export interface RenameCampaignRequestDto {
  /** @format int32 */
  advertId?: number;
  name?: string | null;
}

export interface RevenueCampaignDto {
  /**
   * Id рекламной кампании
   * @format int32
   */
  idCampaign?: number;
  /**
   * Выручка за весь период (суммарная стоимость заказов)
   * @format double
   */
  revenue?: number;
}

export interface SalesBySizeDto {
  name?: string | null;
  /** @format int64 */
  revenue?: number;
}

export interface SalesByWarehouseDto {
  warehouseName?: string | null;
  /** @format int64 */
  revenue?: number;
}

export interface SalesStatisticsDto {
  salesBySize?: SalesBySizeDto[] | null;
  salesByWarehouse?: SalesByWarehouseDto[] | null;
}

export interface SeacatCampaignDto {
  /** @format int64 */
  id?: number;
  /** Название кампании */
  name?: string | null;
  type?: AdvertCampaignTypeEnum;
  /** Название типа кампании */
  typeName?: string | null;
  /**
   * Время окончания кампании
   * @format date-time
   */
  endTime?: string;
  /**
   * Время изменения кампании
   * @format date-time
   */
  changeTime?: string;
  /**
   * Время создания кампании
   * @format date-time
   */
  createTime?: string;
  /**
   * Время старта кампании
   * @format date-time
   */
  startTime?: string;
  status?: AdvertCampaignStatusEnum;
  /** Название статуса кампании */
  statusName?: string | null;
  /** @format int32 */
  budget?: number;
  fromCache?: boolean;
  categories?: SeacatCategoryDto[] | null;
}

export interface SeacatCategoryDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format int32 */
  catalogCpm?: number | null;
  /** @format int32 */
  searchCpm?: number | null;
  products?: SupplierProductDto[] | null;
}

export interface SellableItem {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string[] | null;
  /** @format int32 */
  basePrice?: number;
  /** @format int32 */
  durationMonths?: number;
  /** @format int32 */
  salePercent?: number;
  isAvailable?: boolean;
  orders?: Order[] | null;
  /** @format int32 */
  roleId?: number | null;
}

export interface SellerCatalogFilter {
  /** Навигация по фильтру. */
  navigation?: FilterNavigationDto;
  /** @format date */
  from?: string;
  /** @format date */
  to?: string;
  themes?: number[] | null;
}

/** Продавец. */
export interface SellerDto {
  /**
   * Ид продавца.
   * @format int32
   */
  id?: number;
  /** Название. */
  name?: string | null;
  /** Торговая марка. */
  tradeMark?: string | null;
  /** Ссылка на wb. */
  url?: string | null;
  /** ОРГН. */
  ogrn?: string | null;
  /** ИНН. */
  inn?: string | null;
}

export interface SellerFilterDownloadingDto {
  brandIds?: number[] | null;
  catalogsIds?: number[] | null;
  categoryIds?: number[] | null;
  /** @format int32 */
  feedbacksCountFrom?: number | null;
  /** @format int32 */
  feedbacksCountTo?: number | null;
  inn?: string | null;
  name?: string | null;
  orgn?: string | null;
  productName?: string | null;
  /** @format int32 */
  ratingFrom?: number | null;
  /** @format int32 */
  ratingTo?: number | null;
  /** @format date */
  registrationFrom?: string | null;
  /** @format date */
  registrationTo?: string | null;
  /** @format int32 */
  revenueFrom?: number | null;
  /** @format int32 */
  revenueTo?: number | null;
  active?: boolean | null;
  /** Навигация по фильтру. */
  navigation?: FilterNavigationDto;
  sourceLink?: string | null;
  /** Id полей. */
  fieldIds: number[] | null;
  typeFile?: DownloadingType;
}

export interface SellerFilterDto {
  brandIds?: number[] | null;
  catalogsIds?: number[] | null;
  categoryIds?: number[] | null;
  /** @format int32 */
  feedbacksCountFrom?: number | null;
  /** @format int32 */
  feedbacksCountTo?: number | null;
  inn?: string | null;
  name?: string | null;
  orgn?: string | null;
  productName?: string | null;
  /** @format int32 */
  ratingFrom?: number | null;
  /** @format int32 */
  ratingTo?: number | null;
  /** @format date */
  registrationFrom?: string | null;
  /** @format date */
  registrationTo?: string | null;
  /** @format int32 */
  revenueFrom?: number | null;
  /** @format int32 */
  revenueTo?: number | null;
  active?: boolean | null;
  /** Навигация по фильтру. */
  navigation?: FilterNavigationDto;
  sourceLink?: string | null;
}

export interface SellerFilterStatisticsDto {
  brandIds?: number[] | null;
  catalogsIds?: number[] | null;
  categoryIds?: number[] | null;
  /** @format int32 */
  feedbacksCountFrom?: number | null;
  /** @format int32 */
  feedbacksCountTo?: number | null;
  inn?: string | null;
  name?: string | null;
  orgn?: string | null;
  productName?: string | null;
  /** @format int32 */
  ratingFrom?: number | null;
  /** @format int32 */
  ratingTo?: number | null;
  /** @format date */
  registrationFrom?: string | null;
  /** @format date */
  registrationTo?: string | null;
  /** @format int32 */
  revenueFrom?: number | null;
  /** @format int32 */
  revenueTo?: number | null;
  active?: boolean | null;
  /** @format int32 */
  fieildId?: number;
}

export interface SellerRowDto {
  /**
   * Id.
   * @format int32
   */
  id?: number;
  /** Название продукта. */
  name?: string | null;
  /**
   * Рейтинг.
   * @format float
   */
  raiting?: number;
  /**
   * Товаров с продажами шт.
   * @format int32
   */
  productWithSaleCount?: number;
  /**
   * Продажи шт.
   * @format int64
   */
  saleCount?: number;
  /**
   * Количество отзывов.
   * @format int32
   */
  feedbaksCount?: number;
  /**
   * Дата регистрации.
   * @format date
   */
  registrationDate?: string | null;
  /** ИНН. */
  inn?: string | null;
  /**
   * Средняя выручка в день.
   * @format int64
   */
  dailyRevenueAvg?: number;
  /**
   * Средняя выручка в день с продажами.
   * @format int64
   */
  dailyRevenueSaleAvg?: number;
  /**
   * Упущенная выручка процент.
   * @format double
   */
  lostRevenueProcent?: number;
  /**
   * Выручка.
   * @format int64
   */
  revenue?: number;
  /**
   * Упущенная выручка.
   * @format int64
   */
  lostRevenue?: number;
  /**
   * Брак.
   * @format float
   */
  defectProcent?: number;
  /** Ссылка. */
  link?: string | null;
  /** Оргн. */
  orgn?: string | null;
  /** Адрес. */
  address?: string | null;
  /**
   * Дата обновления.
   * @format date-time
   */
  dateUpdate?: string;
  /**
   * Количество продуктов.
   * @format int32
   */
  prodcutCount?: number;
}

/** Статистика продовца. */
export interface SellerStatisticsCardDto {
  /**
   * Ид продавца.
   * @format int32
   */
  id?: number;
  /**
   * Выручка.
   * @format int64
   */
  revenueSum?: number;
  /**
   * Продажи.
   * @format int64
   */
  salesSum?: number;
  /**
   * Упущенная выручка.
   * @format int64
   */
  loseRevenueSum?: number;
  /**
   * Товаров с продажами. Уст.
   * @format double
   */
  loseSalesSum?: number;
  /**
   * Товаров с продажами.
   * @format int32
   */
  productsWithSales?: number;
}

/** Статистика продовца. */
export interface SellerStatisticsDto {
  /**
   * Ид продавца.
   * @format int32
   */
  id?: number;
  /** Название. */
  name?: string | null;
  /**
   * Выручка.
   * @format int64
   */
  revenueSum?: number;
  /**
   * Продажи.
   * @format int64
   */
  salesCount?: number;
  /**
   * Упущенная выручка.
   * @format int64
   */
  loseRevenueSum?: number;
}

export interface SiteJobTitle {
  /** @format int32 */
  id?: number;
  title?: string | null;
}

export interface SiteRole {
  /** @format int32 */
  id?: number;
  name?: string | null;
  users?: SiteUser[] | null;
  userRoles?: SiteUserRole[] | null;
}

export interface SiteUser {
  /** @format uuid */
  id?: string;
  email?: string | null;
  passwordHash?: string | null;
  phoneNumber?: string | null;
  /** @format date-time */
  registrationDateTime?: string;
  isActive?: boolean;
  /** @format uuid */
  activateKey?: string;
  name?: string | null;
  userPhoto?: SiteUserPhoto;
  apiKeys?: ApiKey[] | null;
  roles?: SiteRole[] | null;
  userRoles?: SiteUserRole[] | null;
  advertCampaigns?: AdvertCampaign[] | null;
  orders?: Order[] | null;
  birthday?: string | null;
  /** @format int32 */
  gender?: number;
  country?: string | null;
  city?: string | null;
  /** @format int32 */
  jobTitle?: number;
  /** @format int32 */
  activeSubscriptionId?: number | null;
  activeSubscription?: UserSubscription;
}

export interface SiteUserPhoto {
  /** @format uuid */
  userId?: string;
  user?: SiteUser;
  /** @format byte */
  data?: string | null;
  photo?: string | null;
}

export interface SiteUserRole {
  /** @format uuid */
  userId?: string;
  user?: SiteUser;
  /** @format int32 */
  roleId?: number;
  role?: SiteRole;
}

export interface StatisticsCampaignDto {
  orders?: OrderCampaignDto[] | null;
  costs?: CostCampaignDto[] | null;
  clicks?: ClickCampaignDto[] | null;
  views?: ViewCampaignDto[] | null;
  ctrs?: CtrCampaignDto[] | null;
  revenues?: RevenueCampaignDto[] | null;
  sumOrders?: SumOrderCampaignDto[] | null;
  cpcs?: CpcCampaignDto[] | null;
  sumAtbs?: SumAtbsCampaignDto[] | null;
}

export interface StockByDateDto {
  /** @format date */
  date?: string;
  /** @format int32 */
  stockCount?: number;
  /** @format int32 */
  revenue?: number;
  /** @format int32 */
  salesCount?: number;
  stocks?: WarehouseStockDto[] | null;
}

export interface StocksStatisticsDto {
  /** @format date */
  date?: string;
  /** @format int32 */
  stock?: number;
  /** @format int32 */
  price?: number;
  /** @format int32 */
  revenue?: number;
  /** @format int32 */
  sales?: number;
}

export interface SubCatalogsDto {
  /** @format int32 */
  id?: number;
  /** @format int64 */
  revenue?: number;
  /** @format int32 */
  sales?: number;
  /** @format int32 */
  productsCountSales?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int32 */
  brandsCount?: number;
  /** @format int32 */
  brandsSales?: number;
  name?: string | null;
}

export interface SubjectsResponseDto {
  name?: string | null;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  count?: number;
}

export interface SumAtbsCampaignDto {
  /** Дата */
  date?: string | null;
  /**
   * Суммарное количество добавлений в корзину (по всем кампаниям)
   * @format int32
   */
  sumAtbs?: number;
}

export interface SumOrderCampaignDto {
  /** Дата */
  date?: string | null;
  /**
   * Суммарное количество заказов (по всем кампаниям)
   * @format int32
   */
  sumOrders?: number;
}

export interface SupplierProductDto {
  /** @format int32 */
  id?: number;
  /** Название товара */
  name?: string | null;
  /**
   * Id категории
   * @format int32
   */
  subjectId?: number;
  /** Ссылка на картинку */
  imageUrl?: string | null;
  /** В наличии ли товар */
  inStock?: boolean;
}

export interface TokensDto {
  accessToken?: string | null;
}

export interface UpdateNmRequestDto {
  add?: number[] | null;
  delete?: number[] | null;
}

/** Пользователь. */
export interface UserLoginDto {
  /**
   * Почта.
   * @maxLength 255
   */
  email?: string | null;
  /**
   * Пароль.
   * @maxLength 255
   */
  passwordHash?: string | null;
}

/** Данные пользователя передаваемые при регистрации. */
export interface UserRegistrationDto {
  /**
   * Почта.
   * @minLength 0
   * @maxLength 100
   */
  email: string;
  /** Хеш пароля. */
  passwordHash?: string | null;
  /** Телефон. */
  phoneNumber?: string | null;
  name?: string | null;
  /**
   * Пол.
   * @format int32
   */
  gender?: number;
  /** Дата рождения. */
  birthday?: string | null;
  /** Город. */
  city?: string | null;
  /** Страна. */
  country?: string | null;
  /**
   * Должность.
   * @format int32
   */
  jobTitle?: number;
}

export interface UserSubscription {
  /** @format int32 */
  id?: number;
  /** @format uuid */
  userId?: string;
  user?: SiteUser;
  /** @format int32 */
  roleId?: number;
  role?: SiteRole;
  /** @format int32 */
  itemId?: number;
  item?: SellableItem;
  /** @format int32 */
  orderId?: number | null;
  order?: Order;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  expiryTime?: string;
  isExpired?: boolean;
}

export interface UserValueDto {
  value?: string | null;
}

export interface ViewCampaignDto {
  /** Дата */
  date?: string | null;
  /**
   * Суммарное количество просмотров (по всем кампаниям)
   * @format int32
   */
  sumViews?: number;
}

/** Информация о складе. */
export interface WarehouseDto {
  /**
   * Id.
   * @format int32
   */
  id?: number;
  /** Название. */
  name?: string | null;
}

export interface WarehouseStockDto {
  /** @format int32 */
  id?: number;
  /** @format date */
  date?: string;
  /** @format int32 */
  stoksCount?: number | null;
  /** @format int64 */
  revenue?: number | null;
  /** @format int64 */
  salesCount?: number | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/api';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      if (r.status == 401) {
        window.location.replace(linksUrl.login);
      }
      if (
        !document.cookie.includes('IsPaidUser=true') &&
        !window.location.pathname.includes(linksUrl.profile)
      ) {
        window.location.replace(linksUrl.profile + linksUrl.profileTariffs);
      }
      if (r.url.includes('doubleSession=true')) {
        window.location.replace(linksUrl.login + '?ds=true');
      }
      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Release
 * @version v1
 * @baseUrl /api
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  apiKey = {
    /**
     * No description
     *
     * @tags ApiKey
     * @name AddApiKeyCreate
     * @request POST:/ApiKey/AddApiKey
     * @secure
     */
    addApiKeyCreate: (data: AddApiKeyRequestDto, params: RequestParams = {}) =>
      this.request<AddApiKeyResponseDto, any>({
        path: `/ApiKey/AddApiKey`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApiKey
     * @name GetCabinetsList
     * @summary Возвращает список кабинетов продавца, по которым можно получить данные.
     * @request GET:/ApiKey/GetCabinets
     * @secure
     */
    getCabinetsList: (params: RequestParams = {}) =>
      this.request<number[], any>({
        path: `/ApiKey/GetCabinets`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApiKey
     * @name GetApiKeysByOwnerList
     * @request GET:/ApiKey/GetApiKeysByOwner
     * @secure
     */
    getApiKeysByOwnerList: (params: RequestParams = {}) =>
      this.request<GetApiKeysByOwnerResponseDto[], any>({
        path: `/ApiKey/GetApiKeysByOwner`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApiKey
     * @name DeleteApiKeyDelete
     * @request DELETE:/ApiKey/DeleteApiKey
     * @secure
     */
    deleteApiKeyDelete: (
      query?: {
        /** @format int32 */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/ApiKey/DeleteApiKey`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name LogoutList
     * @request GET:/Auth/Logout
     * @secure
     */
    logoutList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Auth/Logout`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name LoginCreate
     * @request POST:/Auth/Login
     * @secure
     */
    loginCreate: (data: UserLoginDto, params: RequestParams = {}) =>
      this.request<TokensDto, any>({
        path: `/Auth/Login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name RegistrationCreate
     * @request POST:/Auth/Registration
     * @secure
     */
    registrationCreate: (data: UserRegistrationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Auth/Registration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name ConfirmEmailList
     * @request GET:/Auth/ConfirmEmail
     * @secure
     */
    confirmEmailList: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Auth/ConfirmEmail`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name RecoverPasswordCreate
     * @request POST:/Auth/RecoverPassword
     * @secure
     */
    recoverPasswordCreate: (
      query?: {
        email?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Auth/RecoverPassword`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name UpdatePasswordCreate
     * @request POST:/Auth/UpdatePassword
     * @secure
     */
    updatePasswordCreate: (data: RecoverPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Auth/UpdatePassword`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetListTitlesCreate
     * @summary Получить список выбора профессии.
     * @request POST:/Auth/GetListTitles
     * @secure
     */
    getListTitlesCreate: (params: RequestParams = {}) =>
      this.request<SiteJobTitle[], any>({
        path: `/Auth/GetListTitles`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  autoCampaign = {
    /**
     * No description
     *
     * @tags AutoCampaign
     * @name AutoCampaignList
     * @summary Получение информации о автоматической рекламной кампании продавца по id
     * @request GET:/AutoCampaign
     * @secure
     */
    autoCampaignList: (
      query?: {
        /**
         * Id автоматической рекламной кампании
         * @format int32
         */
        id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AutoCampaignDto, void>({
        path: `/AutoCampaign`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создают автоматическую кампанию. Максимум 1 запрос в 20 секунд.
     *
     * @tags AutoCampaign
     * @name AutoCampaignCreate
     * @summary Создание рекламной кампании.
     * @request POST:/AutoCampaign
     * @secure
     */
    autoCampaignCreate: (
      data: CreateAutoCampaignRequestDto,
      query?: {
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, void>({
        path: `/AutoCampaign`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Метод позволяет добавлять и удалять номенклатуры. Допускается 1 запрос в секунду. Важно: Добавить можно только те номенклатуры, которые вернутся в ответе метода "Список номенклатур для автоматической кампании". Удалить единственную номенклатуру из кампании нельзя. Проверки по параметру delete не предусмотрено. Если пришел ответ со статус-кодом 200, а изменений не произошло, то проверьте запрос на соответствие документации.
     *
     * @tags AutoCampaign
     * @name AutoCampaignPartialUpdate
     * @summary Изменение списка номенклатур в автоматической кампании.
     * @request PATCH:/AutoCampaign
     * @secure
     */
    autoCampaignPartialUpdate: (
      data: UpdateNmRequestDto,
      query?: {
        /** @format int32 */
        Id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/AutoCampaign`,
        method: 'PATCH',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  brand = {
    /**
     * No description
     *
     * @tags Brand
     * @name GetBrandsCreate
     * @summary Получить список брендов.
     * @request POST:/Brand/GetBrands
     * @secure
     */
    getBrandsCreate: (data: BrandFilterDto, params: RequestParams = {}) =>
      this.request<BrandDto[], any>({
        path: `/Brand/GetBrands`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name GetFieldsCreate
     * @summary Получить список полей.
     * @request POST:/Brand/GetFields
     * @secure
     */
    getFieldsCreate: (params: RequestParams = {}) =>
      this.request<BrandFieldDto[], any>({
        path: `/Brand/GetFields`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name CountResultsCreate
     * @summary Подсчет количества брендов.
     * @request POST:/Brand/CountResults
     * @secure
     */
    countResultsCreate: (data: BrandFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/Brand/CountResults`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name CreateDownloadFileCreate
     * @summary Начать делать выгрузку.
     * @request POST:/Brand/CreateDownloadFile
     * @secure
     */
    createDownloadFileCreate: (data: BrandFilterDownloadingDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/Brand/CreateDownloadFile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name GetFileByIdList
     * @summary Выгрузка готового файла.
     * @request GET:/Brand/GetFileById
     * @secure
     */
    getFileByIdList: (
      query?: {
        /**
         * Id выгразка.
         * @format uuid
         */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Brand/GetFileById`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  brandCard = {
    /**
     * No description
     *
     * @tags BrandCard
     * @name GetBrandCreate
     * @request POST:/BrandCard/GetBrand
     * @secure
     */
    getBrandCreate: (
      query?: {
        /** @format int32 */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BrandDto, any>({
        path: `/BrandCard/GetBrand`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BrandCard
     * @name GetBrandStatisticsFilterCreate
     * @summary Статистика бренда по фильтру с датой.
     * @request POST:/BrandCard/GetBrandStatisticsFilter
     * @secure
     */
    getBrandStatisticsFilterCreate: (data: ProductFilterDateDto, params: RequestParams = {}) =>
      this.request<BrandStatisticsDto, any>({
        path: `/BrandCard/GetBrandStatisticsFilter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  campaign = {
    /**
     * No description
     *
     * @tags Campaign
     * @name PageCountList
     * @request GET:/Campaign/PageCount
     * @secure
     */
    pageCountList: (
      query?: {
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageCountDto, void>({
        path: `/Campaign/PageCount`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Метод делает 2 последовательных вызова api и из за этого может работать медленно.
     *
     * @tags Campaign
     * @name CampaignList
     * @summary Получение всех рекламных кампаний продавца
     * @request GET:/Campaign
     * @secure
     */
    campaignList: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignDto[], void>({
        path: `/Campaign`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Метод позволяет удалять кампании в статусе 4 - готова к запуску. Допускается 5 запросов в секунду. После удаления кампания некоторое время будет находиться в -1 статусе. Полное удаление кампании занимает от 3 до 10 минут.
     *
     * @tags Campaign
     * @name CampaignDelete
     * @summary Удаление кампании.
     * @request DELETE:/Campaign
     * @secure
     */
    campaignDelete: (
      query?: {
        /** @format int32 */
        IdCampaign?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Campaign`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name ChangeNamePartialUpdate
     * @summary Изменение названия кампании
     * @request PATCH:/Campaign/ChangeName
     * @secure
     */
    changeNamePartialUpdate: (
      data: RenameCampaignRequestDto,
      query?: {
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Campaign/ChangeName`,
        method: 'PATCH',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name AddBalancePartialUpdate
     * @summary Пополнение баланса кампании
     * @request PATCH:/Campaign/AddBalance
     * @secure
     */
    addBalancePartialUpdate: (
      data: DepositRequestDto,
      query?: {
        /** @format int32 */
        Id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DepositResponseDto, void>({
        path: `/Campaign/AddBalance`,
        method: 'PATCH',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Метод позволяет запускать кампании находящиеся в статусах 4 - готова к запуску или 11 - кампания на паузе. Допускается 5 запросов в секунду. Для запуска кампании со статусом 11 необходимо наличие у неё пополненного бюджета. Чтобы запустить кампанию со статусом 4 необходимо выполнить два условия (поочередность действий значения не имеет): 1. После создания кампании в кабинете ВБ.Продвижение необходимо нажать кнопку "Применить изменения". 2. Установить бюджет.
     *
     * @tags Campaign
     * @name StartList
     * @summary Запуск кампании.
     * @request GET:/Campaign/Start
     * @secure
     */
    startList: (
      query?: {
        /** @format int32 */
        Id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Campaign/Start`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description кампании в статусе "9 - идут показы" - можно запаузить, сделав GET на /adv/v0/pause?id=***. Допускается 5 запросов в секунду.
     *
     * @tags Campaign
     * @name PauseList
     * @summary Пауза кампании.
     * @request GET:/Campaign/Pause
     * @secure
     */
    pauseList: (
      query?: {
        /** @format int32 */
        Id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Campaign/Pause`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Метод позволяет завершить кампанию, находящуюся в статусе 9 или 11 или 4. Допускается 5 запросов в секунду.
     *
     * @tags Campaign
     * @name StopList
     * @summary Стоп кампании.
     * @request GET:/Campaign/Stop
     * @secure
     */
    stopList: (
      query?: {
        /** @format int32 */
        Id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Campaign/Stop`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign
     * @name GetStatisticsCreate
     * @summary Получаение статистики по всем рекламным кампаниям
     * @request POST:/Campaign/GetStatistics
     * @secure
     */
    getStatisticsCreate: (
      data: GetCampaignStatisticsDto,
      query?: {
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<StatisticsCampaignDto, void>({
        path: `/Campaign/GetStatistics`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  categories = {
    /**
     * No description
     *
     * @tags Categories
     * @name GetCatalogsCreate
     * @summary Получение списка каталогов.
     * @request POST:/Categories/GetCatalogs
     * @secure
     */
    getCatalogsCreate: (params: RequestParams = {}) =>
      this.request<CatalogDto[], any>({
        path: `/Categories/GetCatalogs`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetMainMenuList
     * @request GET:/Categories/GetMainMenu
     * @secure
     */
    getMainMenuList: (params: RequestParams = {}) =>
      this.request<MenuDto[], any>({
        path: `/Categories/GetMainMenu`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetCategoriesCreate
     * @summary Получение списка категорий.
     * @request POST:/Categories/GetCategories
     * @secure
     */
    getCategoriesCreate: (
      query?: {
        /**
         * Id Каталога.
         * @format int32
         */
        parentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CategoryDto[], any>({
        path: `/Categories/GetCategories`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetCategoryStatisticsCreate
     * @summary Получение статистки для категории.
     * @request POST:/Categories/GetCategoryStatistics
     * @secure
     */
    getCategoryStatisticsCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<CategoryStatisticsDto, any>({
        path: `/Categories/GetCategoryStatistics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetCategoryStatisticsByDateCreate
     * @summary Статистика категории по фильтру с датой.
     * @request POST:/Categories/GetCategoryStatisticsByDate
     * @secure
     */
    getCategoryStatisticsByDateCreate: (data: ProductFilterDateDto, params: RequestParams = {}) =>
      this.request<CatalogStatisticsByDate, any>({
        path: `/Categories/GetCategoryStatisticsByDate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetScheduleCreate
     * @request POST:/Categories/GetSchedule
     * @secure
     */
    getScheduleCreate: (data: CatalogFilterDto, params: RequestParams = {}) =>
      this.request<CatalogStocksDto[], any>({
        path: `/Categories/GetSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetSubcategoriesStatisticsCreate
     * @request POST:/Categories/GetSubcategoriesStatistics
     * @secure
     */
    getSubcategoriesStatisticsCreate: (data: CatalogFilterDto, params: RequestParams = {}) =>
      this.request<SubCatalogsDto[], any>({
        path: `/Categories/GetSubcategoriesStatistics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name GetSellersCreate
     * @request POST:/Categories/GetSellers
     * @secure
     */
    getSellersCreate: (data: SellerCatalogFilter, params: RequestParams = {}) =>
      this.request<SellerRowDto[], any>({
        path: `/Categories/GetSellers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  debug = {
    /**
     * @description Structure-capturing operator "@" в шаблоне на данный момент не поддерживается.
     *
     * @tags Debug
     * @name PostDebug
     * @summary Создает запись в логах. Метод активен только для тестовой среды.
     * @request POST:/Debug/Log
     * @secure
     */
    postDebug: (
      data: any[],
      query?: {
        /** Уровень серьезности лога от 0 (Trace) до 5 (Critical) и 6 (None) */
        logLevel?: LogLevel;
        /** Шаблон лога. Для добавления переменных использовать фигурные скобки и произвольное название поля. Прим: "Переменная приняла неправильное значение: {actualValue}, ожидаемое значение: {expectedValue}." */
        template?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Debug/Log`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  dictionary = {
    /**
     * No description
     *
     * @tags Dictionary
     * @name GetProductsBySubjectCreate
     * @summary Получение товаров пользователя по списку категорий товаров
     * @request POST:/Dictionary/GetProductsBySubject
     * @secure
     */
    getProductsBySubjectCreate: (data: number[], params: RequestParams = {}) =>
      this.request<SupplierProductDto[], void>({
        path: `/Dictionary/GetProductsBySubject`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Метод делает 2 последовательных вызова api и из за этого может работать медленно.
     *
     * @tags Dictionary
     * @name GetProductsList
     * @summary Получение товаров пользователя
     * @request GET:/Dictionary/GetProducts
     * @secure
     */
    getProductsList: (params: RequestParams = {}) =>
      this.request<SupplierProductDto[], void>({
        path: `/Dictionary/GetProducts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name GetCategoriesList
     * @summary Получение категорий товаров пользователя
     * @request GET:/Dictionary/GetCategories
     * @secure
     */
    getCategoriesList: (params: RequestParams = {}) =>
      this.request<SubjectsResponseDto[], void>({
        path: `/Dictionary/GetCategories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  favoriteProducts = {
    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name GetAllLabelCororsList
     * @summary Получить все цвета для меток.
     * @request GET:/FavoriteProducts/GetAllLabelCorors
     * @secure
     */
    getAllLabelCororsList: (params: RequestParams = {}) =>
      this.request<LabelColorDto[], any>({
        path: `/FavoriteProducts/GetAllLabelCorors`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name GetLabelsCreate
     * @summary Получить все метки пользователя для заданного маркетплейса.
     * @request POST:/FavoriteProducts/GetLabels
     * @secure
     */
    getLabelsCreate: (
      query?: {
        /**
         * Id площадки.
         * @format int32
         */
        marketplaceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LabelDto, any>({
        path: `/FavoriteProducts/GetLabels`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name GetProductsCreate
     * @summary Получить все избранные продукты.
     * @request POST:/FavoriteProducts/GetProducts
     * @secure
     */
    getProductsCreate: (
      data: FilterNavigationDto,
      query?: {
        /**
         * Id площадки.
         * @format int32
         */
        marketplaceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LabelProductContentDto, any>({
        path: `/FavoriteProducts/GetProducts`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name UpdateLabelPartialUpdate
     * @summary Обновление метки по Id.
     * @request PATCH:/FavoriteProducts/UpdateLabel
     * @secure
     */
    updateLabelPartialUpdate: (data: LabelDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/FavoriteProducts/UpdateLabel`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name InsertProductLabelUpdate
     * @summary Добавление метки-продукта.
     * @request PUT:/FavoriteProducts/InsertProductLabel
     * @secure
     */
    insertProductLabelUpdate: (data: LabelProductDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/FavoriteProducts/InsertProductLabel`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name DeleteProductLabelDelete
     * @summary Удаление метки-продукта.
     * @request DELETE:/FavoriteProducts/DeleteProductLabel
     * @secure
     */
    deleteProductLabelDelete: (
      query?: {
        /**
         * Id продукта.
         * @format int32
         */
        productId?: number;
        /**
         * Id маркетплейса.
         * @format int32
         */
        marketPlaceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/FavoriteProducts/DeleteProductLabel`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name UpdateLabelProductPartialUpdate
     * @summary Обнавление метки-продукта (выброного продукта).
     * @request PATCH:/FavoriteProducts/UpdateLabelProduct
     * @secure
     */
    updateLabelProductPartialUpdate: (data: LabelProductDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/FavoriteProducts/UpdateLabelProduct`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name CreateDownloadFileCreate
     * @summary Начать делать выгрузку.
     * @request POST:/FavoriteProducts/CreateDownloadFile
     * @secure
     */
    createDownloadFileCreate: (data: FavoriteDownloadDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/FavoriteProducts/CreateDownloadFile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name GetFileByIdList
     * @summary Выгрузка готового файла.
     * @request GET:/FavoriteProducts/GetFileById
     * @secure
     */
    getFileByIdList: (
      query?: {
        /**
         * Id выгразки.
         * @format uuid
         */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/FavoriteProducts/GetFileById`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FavoriteProducts
     * @name GetCountCreate
     * @summary Количество товаров в избранном.
     * @request POST:/FavoriteProducts/GetCount
     * @secure
     */
    getCountCreate: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/FavoriteProducts/GetCount`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  feedback = {
    /**
     * No description
     *
     * @tags Feedback
     * @name GetSpecialtiesList
     * @request GET:/Feedback/GetSpecialties
     * @deprecated
     * @secure
     */
    getSpecialtiesList: (params: RequestParams = {}) =>
      this.request<Record<string, number>, any>({
        path: `/Feedback/GetSpecialties`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackFormCreate
     * @request POST:/Feedback/FeedbackForm
     * @secure
     */
    feedbackFormCreate: (data: FeedbackFormDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Feedback/FeedbackForm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  fields = {
    /**
     * No description
     *
     * @tags Fields
     * @name GetFieldsCreate
     * @summary Получение списка полей таблицы продукты.
     * @request POST:/Fields/GetFields
     * @secure
     */
    getFieldsCreate: (params: RequestParams = {}) =>
      this.request<FilterFieldDto[], any>({
        path: `/Fields/GetFields`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fields
     * @name GetCountriesCreate
     * @summary Получение списка стран.
     * @request POST:/Fields/GetCountries
     * @secure
     */
    getCountriesCreate: (params: RequestParams = {}) =>
      this.request<FilterFieldDto[], any>({
        path: `/Fields/GetCountries`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  finance = {
    /**
     * @description Метод позволяет получать информацию о счёте, балансе и бонусах продавца. Допускается 1 запрос в секунду.
     *
     * @tags Finance
     * @name FinanceList
     * @summary Баланс.
     * @request GET:/Finance
     * @secure
     */
    financeList: (params: RequestParams = {}) =>
      this.request<BalanceResponseDto, void>({
        path: `/Finance`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  history = {
    /**
     * No description
     *
     * @tags History
     * @name GetHistoryCreate
     * @request POST:/History/GetHistory
     * @secure
     */
    getHistoryCreate: (data: HistoryOffsetDto, params: RequestParams = {}) =>
      this.request<HistoryDto[], any>({
        path: `/History/GetHistory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags History
     * @name GetCountCreate
     * @request POST:/History/GetCount
     * @secure
     */
    getCountCreate: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/History/GetCount`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  payment = {
    /**
     * No description
     *
     * @tags Payment
     * @name SellableItemsList
     * @summary Получает список товарных позиций, доступных к покупке.
     * @request GET:/Payment/SellableItems
     * @secure
     */
    sellableItemsList: (params: RequestParams = {}) =>
      this.request<SellableItem[], any>({
        path: `/Payment/SellableItems`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name OrdersList
     * @summary Получает список заказов.
     * @request GET:/Payment/Orders
     * @secure
     */
    ordersList: (params: RequestParams = {}) =>
      this.request<OrderHistoryDto[], any>({
        path: `/Payment/Orders`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name InitCreate
     * @summary Инициализирует оплату для заданной товарной позиции и длительности.
     * @request POST:/Payment/Init
     * @secure
     */
    initCreate: (
      query?: {
        /**
         * Id товарной позиции
         * @format int32
         */
        itemId?: number;
        /**
         * Отвечает за автопродление платежа после истечения срока действия подписки
         * @default false
         */
        recurrent?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<InitPaymentDto, void>({
        path: `/Payment/Init`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name NotifyCreate
     * @summary Метод реализуется на стороне Мерчанта для получения уведомлений об изменении статуса платежа.
     * @request POST:/Payment/Notify
     * @secure
     */
    notifyCreate: (data: NotificationPaymentRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Payment/Notify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  productCard = {
    /**
     * No description
     *
     * @tags ProductCard
     * @name GetProductCardCreate
     * @summary Получение карточки товара wb.
     * @request POST:/ProductCard/GetProductCard
     * @secure
     */
    getProductCardCreate: (
      query?: {
        /**
         * Id товара.
         * @format int32
         */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductCardDto, any>({
        path: `/ProductCard/GetProductCard`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCard
     * @name GetStocksStatisticsCreate
     * @summary Получить статистику и остатки для продукта.
     * @request POST:/ProductCard/GetStocksStatistics
     * @secure
     */
    getStocksStatisticsCreate: (data: ProductCardStatisticsFilterDto, params: RequestParams = {}) =>
      this.request<ProductCardStatisticsDto, any>({
        path: `/ProductCard/GetStocksStatistics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCard
     * @name GetSalesByWhSzCreate
     * @summary Получение продаж и выручки по складу размеру.
     * @request POST:/ProductCard/GetSalesByWhSz
     * @secure
     */
    getSalesByWhSzCreate: (data: ProductCardStatisticsFilterDto, params: RequestParams = {}) =>
      this.request<SalesStatisticsDto, any>({
        path: `/ProductCard/GetSalesByWhSz`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCard
     * @name GetStocksCreate
     * @summary Получить остатки по складам.
     * @request POST:/ProductCard/GetStocks
     * @secure
     */
    getStocksCreate: (data: ProductCardStatisticsFilterDto, params: RequestParams = {}) =>
      this.request<ContainerStockByDateDto, any>({
        path: `/ProductCard/GetStocks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  productFilter = {
    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCountProductsCreate
     * @summary Подсчет количества продуктов.
     * @request POST:/ProductFilter/GetCountProducts
     * @secure
     */
    getCountProductsCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetCountProducts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetProductStatisticsCreate
     * @summary Подсчет статистики для страницы продукты.
     * @request POST:/ProductFilter/GetProductStatistics
     * @secure
     */
    getProductStatisticsCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<ProductStatisticsDto, any>({
        path: `/ProductFilter/GetProductStatistics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCountBrendsCreate
     * @summary Подсчет количества уникальных брендов.
     * @request POST:/ProductFilter/GetCountBrends
     * @deprecated
     * @secure
     */
    getCountBrendsCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetCountBrends`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCountSellersCreate
     * @summary Подсчет количества уникальных продавцов.
     * @request POST:/ProductFilter/GetCountSellers
     * @deprecated
     * @secure
     */
    getCountSellersCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetCountSellers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCountSalesCreate
     * @summary Подсчет количества продаж.
     * @request POST:/ProductFilter/GetCountSales
     * @deprecated
     * @secure
     */
    getCountSalesCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetCountSales`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetSumRevenueCreate
     * @summary Подсчет суммы выручки.
     * @request POST:/ProductFilter/GetSumRevenue
     * @deprecated
     * @secure
     */
    getSumRevenueCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetSumRevenue`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetAvgRevenueDayCreate
     * @summary Подсчет средней выручки в день.
     * @request POST:/ProductFilter/GetAvgRevenueDay
     * @secure
     */
    getAvgRevenueDayCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetAvgRevenueDay`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetAvgPriceCreate
     * @summary Подсчет средней цены продукта.
     * @request POST:/ProductFilter/GetAvgPrice
     * @secure
     */
    getAvgPriceCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetAvgPrice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetAvgBuyoutPercentCreate
     * @summary Подсчет среднего процента выкупа.
     * @request POST:/ProductFilter/GetAvgBuyoutPercent
     * @secure
     */
    getAvgBuyoutPercentCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/GetAvgBuyoutPercent`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name SumLoseRevenueCreate
     * @summary Подсчет суммы упущенной выручки.
     * @request POST:/ProductFilter/SumLoseRevenue
     * @secure
     */
    sumLoseRevenueCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/ProductFilter/SumLoseRevenue`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetProductsByDateCreate
     * @summary Получить список продуктов по дате.
     * @request POST:/ProductFilter/GetProductsByDate
     * @secure
     */
    getProductsByDateCreate: (data: ProductFilterDateDto, params: RequestParams = {}) =>
      this.request<ProductDto[], any>({
        path: `/ProductFilter/GetProductsByDate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetProductsCreate
     * @summary Получить список продуктов.
     * @request POST:/ProductFilter/GetProducts
     * @secure
     */
    getProductsCreate: (data: ProductFilterDto, params: RequestParams = {}) =>
      this.request<ProductDto[], any>({
        path: `/ProductFilter/GetProducts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetBrandsCreate
     * @summary Получить бренды по фильру. Лимит 20.
     * @request POST:/ProductFilter/GetBrands
     * @secure
     */
    getBrandsCreate: (data: FilterTextSearchDto, params: RequestParams = {}) =>
      this.request<BrandDto[], any>({
        path: `/ProductFilter/GetBrands`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCategoriesCreate
     * @summary Получить категории по фильру. Лимит 20.
     * @request POST:/ProductFilter/GetCategories
     * @secure
     */
    getCategoriesCreate: (data: FilterTextSearchDto, params: RequestParams = {}) =>
      this.request<CategoryDto[], any>({
        path: `/ProductFilter/GetCategories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCatalogsCreate
     * @summary Получить каталоги по фильру. Лимит 20.
     * @request POST:/ProductFilter/GetCatalogs
     * @secure
     */
    getCatalogsCreate: (data: FilterTextSearchDto, params: RequestParams = {}) =>
      this.request<CategoryDto[], any>({
        path: `/ProductFilter/GetCatalogs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetCountriesCreate
     * @summary Получить категории по фильру. Лимит 20.
     * @request POST:/ProductFilter/GetCountries
     * @secure
     */
    getCountriesCreate: (data: FilterTextSearchDto, params: RequestParams = {}) =>
      this.request<CountryDto[], any>({
        path: `/ProductFilter/GetCountries`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name CreateDownloadFileByDateCreate
     * @summary Начать делать выгрузку по дате.
     * @request POST:/ProductFilter/CreateDownloadFileByDate
     * @secure
     */
    createDownloadFileByDateCreate: (
      data: ProductFilterDateDownloadingDto,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/ProductFilter/CreateDownloadFileByDate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name CreateDownloadFileCreate
     * @summary Начать делать выгрузку.
     * @request POST:/ProductFilter/CreateDownloadFile
     * @secure
     */
    createDownloadFileCreate: (data: ProductFilterDownloadingDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ProductFilter/CreateDownloadFile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFilter
     * @name GetFileByIdList
     * @summary Выгрузка готового файла.
     * @request GET:/ProductFilter/GetFileById
     * @secure
     */
    getFileByIdList: (
      query?: {
        /**
         * Id выгразки.
         * @format uuid
         */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/ProductFilter/GetFileById`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  profile = {
    /**
     * No description
     *
     * @tags Profile
     * @name SaveValueUpdate
     * @summary Сохраняет или обновляет значение по ключу.
     * @request PUT:/Profile/SaveValue
     * @secure
     */
    saveValueUpdate: (
      query?: {
        /** Ключ значения. Макс 100 символов */
        key?: string;
        /** Значение. Макс 1000 символов */
        value?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Profile/SaveValue`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetValueList
     * @summary Возвращает значение по ключу.
     * @request GET:/Profile/GetValue
     * @secure
     */
    getValueList: (
      query?: {
        /** Ключ значения. */
        key?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserValueDto, any>({
        path: `/Profile/GetValue`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name SetNameCreate
     * @summary Задать имя.
     * @request POST:/Profile/SetName
     * @secure
     */
    setNameCreate: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Profile/SetName`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name SetPhoneNumberCreate
     * @summary Задать телефон.
     * @request POST:/Profile/SetPhoneNumber
     * @secure
     */
    setPhoneNumberCreate: (
      query?: {
        /** Телефон. */
        phoneNumber?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Profile/SetPhoneNumber`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name SetPhotoCreate
     * @summary Задать фото.
     * @request POST:/Profile/SetPhoto
     * @secure
     */
    setPhotoCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Profile/SetPhoto`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name SetPhoto2Create
     * @summary Задать фото.
     * @request POST:/Profile/SetPhoto2
     * @secure
     */
    setPhoto2Create: (data: PhotoDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Profile/SetPhoto2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetPhotoList
     * @summary Получить фото.
     * @request GET:/Profile/GetPhoto
     * @secure
     */
    getPhotoList: (params: RequestParams = {}) =>
      this.request<PhotoDto, any>({
        path: `/Profile/GetPhoto`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetPhoto2Create
     * @summary Получить фото.
     * @request POST:/Profile/GetPhoto2
     * @secure
     */
    getPhoto2Create: (params: RequestParams = {}) =>
      this.request<PhotoDto, any>({
        path: `/Profile/GetPhoto2`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetProfileCreate
     * @summary Получить профиль.
     * @request POST:/Profile/GetProfile
     * @secure
     */
    getProfileCreate: (params: RequestParams = {}) =>
      this.request<ProfileDto, any>({
        path: `/Profile/GetProfile`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetCurrentSubscriptionList
     * @summary Получить информацию о текущем тарифе.
     * @request GET:/Profile/GetCurrentSubscription
     * @secure
     */
    getCurrentSubscriptionList: (params: RequestParams = {}) =>
      this.request<CurrentSubscriptionDto, any>({
        path: `/Profile/GetCurrentSubscription`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ChanagePasswordCreate
     * @summary Сменить пароль.
     * @request POST:/Profile/ChanagePassword
     * @secure
     */
    chanagePasswordCreate: (data: ChanagePasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Profile/ChanagePassword`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  seacatCampaign = {
    /**
     * No description
     *
     * @tags SeacatCampaign
     * @name SeacatCampaignList
     * @summary Получение информации о автоматической рекламной кампании продавца по id
     * @request GET:/SeacatCampaign
     * @secure
     */
    seacatCampaignList: (
      query?: {
        /**
         * Id автоматической рекламной кампании
         * @format int32
         */
        id?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SeacatCampaignDto, void>({
        path: `/SeacatCampaign`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SeacatCampaign
     * @name SeacatCampaignCreate
     * @summary Создание рекламной кампании Поиск + Каталог
     * @request POST:/SeacatCampaign
     * @secure
     */
    seacatCampaignCreate: (
      data: CreateSeacatCampaignRequestDto,
      query?: {
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, void>({
        path: `/SeacatCampaign`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SeacatCampaign
     * @name GetSearchCpmList
     * @request GET:/SeacatCampaign/GetSearchCpm
     * @secure
     */
    getSearchCpmList: (
      query?: {
        /** @format int32 */
        subjectId?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CpmResponseDto[], void>({
        path: `/SeacatCampaign/GetSearchCpm`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SeacatCampaign
     * @name GetCatalogCpmList
     * @request GET:/SeacatCampaign/GetCatalogCpm
     * @secure
     */
    getCatalogCpmList: (
      query?: {
        /** @format int32 */
        categoryId?: number;
        /** @format int32 */
        cabinetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CpmResponseDto[], void>({
        path: `/SeacatCampaign/GetCatalogCpm`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  sellerCard = {
    /**
     * No description
     *
     * @tags SellerCard
     * @name SellerCreate
     * @summary Получить продавца.
     * @request POST:/SellerCard/Seller
     * @secure
     */
    sellerCreate: (
      query?: {
        /**
         * Id продавца
         * @format int32
         */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SellerDto, any>({
        path: `/SellerCard/Seller`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SellerCard
     * @name GetSellerStatisticsByFilterCreate
     * @summary Статистика продавца по фильтру с датой.
     * @request POST:/SellerCard/GetSellerStatisticsByFilter
     * @secure
     */
    getSellerStatisticsByFilterCreate: (data: ProductFilterDateDto, params: RequestParams = {}) =>
      this.request<SellerStatisticsCardDto, any>({
        path: `/SellerCard/GetSellerStatisticsByFilter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  sellers = {
    /**
     * No description
     *
     * @tags Sellers
     * @name GetFieldsSellerCreate
     * @summary Получение списока полей таблицы продавцы.
     * @request POST:/Sellers/GetFieldsSeller
     * @secure
     */
    getFieldsSellerCreate: (params: RequestParams = {}) =>
      this.request<FilterFieldDto[], any>({
        path: `/Sellers/GetFieldsSeller`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sellers
     * @name GetStatisticsCreate
     * @summary Статистика для продавцов по фильтру.
     * @request POST:/Sellers/GetStatistics
     * @secure
     */
    getStatisticsCreate: (data: SellerFilterStatisticsDto, params: RequestParams = {}) =>
      this.request<SellerStatisticsDto[], any>({
        path: `/Sellers/GetStatistics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sellers
     * @name GetSellersCreate
     * @summary Получить продажи по списку.
     * @request POST:/Sellers/GetSellers
     * @secure
     */
    getSellersCreate: (data: SellerFilterDto, params: RequestParams = {}) =>
      this.request<SellerRowDto[], any>({
        path: `/Sellers/GetSellers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sellers
     * @name GetCountSellersCreate
     * @summary Получить количест продавцов по фильтру.
     * @request POST:/Sellers/GetCountSellers
     * @secure
     */
    getCountSellersCreate: (data: SellerFilterDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/Sellers/GetCountSellers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sellers
     * @name CreateDownloadFileCreate
     * @summary Начать делать выгрузку.
     * @request POST:/Sellers/CreateDownloadFile
     * @secure
     */
    createDownloadFileCreate: (data: SellerFilterDownloadingDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/Sellers/CreateDownloadFile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sellers
     * @name GetFileByIdList
     * @summary Выгрузка готового файла.
     * @request GET:/Sellers/GetFileById
     * @secure
     */
    getFileByIdList: (
      query?: {
        /**
         * Id выгразка.
         * @format uuid
         */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Sellers/GetFileById`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
}
