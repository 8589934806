import { ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { linksUrl } from '../../../consts/linksUrl';
import './table-item.scss';
import { bemCN } from '../../../configs/bem-classname';
import { CustomCellRendererProps } from 'ag-grid-react';
import { LocalStorageVariableName } from '../../../services/localStorage-service';
import { PathDto, ProductDto } from '../../../api/Api';
import { formatFullDate } from '../../../utils/date';
import { Button, ColorsButton, SizesButton } from '../../button/button';
import { FavoriteTableItem } from '../favorite-table-item/favorite-table-item';

// interface TableItemProps extends CustomCellRendererProps {
//   typeEntity: LocalStorageVariableName;
// }

const tableItemCN = bemCN('table-item');
export const TableItem = (params: CustomCellRendererProps) => {
  let columnName = params.colDef?.field;
  let obj = params.data;
  let typeEntity = JSON.parse(JSON.stringify(params.colDef)).cellRenderParams.typeEntity;

  if (columnName?.includes('date') || columnName === 'firstfeedback') {
    return <p className={tableItemCN()}>{formatFullDate(params.value) || '-'}</p>;
  }
  switch (columnName) {
    case 'name': {
      if (typeEntity === LocalStorageVariableName.productColumns) {
        return (
          <Link className={tableItemCN()} to={linksUrl.product + '/' + obj.id}>
            {obj.photourl && <img src={obj.photourl}></img>}
            {params.value}
          </Link>
        );
      } else if (typeEntity === LocalStorageVariableName.brandColumn) {
        // return <Link to={linksUrl.brand + '/' + obj.id}>{params.value || '-'}</Link>;
        return params.value ? (
          <Link to={linksUrl.brand + '/' + obj.id}>
            {params.value || params.value != '' ? params.value : '-'}
          </Link>
        ) : (
          <p>{params.value || '-'}</p>
        );
      } else if (typeEntity === LocalStorageVariableName.sellerColumn) {
        // return <Link to={linksUrl.seller + '/' + obj.id}>{params.value || ''}</Link>;
        return <Link to={linksUrl.seller + '/' + obj.id}>{params.value ? params.value : '-'}</Link>;
      } else if (typeEntity === LocalStorageVariableName.favoriteColumn) {
        return (
          <FavoriteTableItem
            idProduct={Number(obj.id)}
            name={params.value.toString()}
            link={linksUrl.product + '/' + obj.id}
            labelId={Number(obj.labelid)}
          />
        );
      } else if (typeEntity === LocalStorageVariableName.subcategoryColumns) {
        return (
          <Link target="_blank" to={linksUrl.subcategory + '?themeCategories=' + obj.id}>
            {params.value ? params.value : '-'}
          </Link>
        );
      }
      return (
        <Link to={linksUrl.product + '/' + obj.id}>
          {obj.photourl && <img src={obj.photourl}></img>}
          {params.value}
        </Link>
      );
    }
    // case 'category': {
    //   return <Link to={linksUrl.subcategory + '/' + obj.categoryid}>{params.value}</Link>;
    // }
    case 'isnew': {
      return <p>{params.value == 0 ? 'Нет' : 'Да'}</p>;
    }
    case 'gender': {
      return <p>{params.value || '-'}</p>;
    }
    case 'sizes': {
      return <p>{params.value || '-'}</p>;
    }
    case 'description': {
      return <p>{params.value || '-'}</p>;
    }
    case 'options': {
      return <p>{params.value || '-'}</p>;
    }
    case 'brandname': {
      return params.value ? (
        <Link to={linksUrl.brand + '/' + obj.brandid}>{params.value || '-'}</Link>
      ) : (
        <p>{params.value || '-'}</p>
      );
    }
    // case 'delete': {
    //   return (
    //     <Button size={SizesButton.small} color={ColorsButton.transition} icon={<DeleteIcon />} />
    //   );
    // }
    // case 'subcategory1': {
    //   return <Link to={linksUrl.subcategory + '/' + obj.subcategoryid}>{params.value}</Link>;
    // }
    case 'category': {
      return (
        <div className={tableItemCN()}>
          {obj.path ? (
            obj.path.map((item: PathDto, index: number) => (
              <Link
                className={tableItemCN()}
                key={item.id}
                to={
                  linksUrl.subcategory +
                  (item.isSubject
                    ? '?subCategoriesIds=' + item.id + '&themeCategories=' + obj.path[index - 1].id
                    : '?themeCategories=' + item.id)
                }
              >
                {item.name}
                {item.isSubject == false && <span> / </span>}
              </Link>
            ))
          ) : (
            <Link to={linksUrl.subcategory + '?themeCategories=' + obj.subcategoryid}>
              {params.value}
            </Link>
          )}
        </div>
      );
    }
    case 'salername': {
      return <Link to={linksUrl.seller + '/' + obj.salerid}>{params.value}</Link>;
    }
    case 'id': {
      if (typeEntity === LocalStorageVariableName.sellerColumn) {
        return (
          <Link target="_blank" to={'https://www.wildberries.ru/seller/' + params.value}>
            {params.value}
          </Link>
        );
      } else return;
    }
    case 'articul': {
      return (
        <Link target="_blank" to={obj.link}>
          {params.value}
        </Link>
      );
    }
    case 'link': {
      return (
        <Link target="_blank" to={obj.link}>
          {params.value}
        </Link>
      );
    }
    case 'photourl': {
      return (
        <Link target="_blank" to={params.value}>
          {params.value}
        </Link>
      );
    }
    case 'brandname': {
      return <Link to={linksUrl.brand + '/' + obj.brandid}>{params.value}</Link>;
    }
    case 'inn': {
      return (
        <p className={tableItemCN()}>
          {Number(params.value) ? Number(params.value) : params.value ? params.value : '-'}
        </p>
      );
    }
    case 'orgn': {
      return (
        <p className={tableItemCN()}>
          {Number(params.value) ? Number(params.value) : params.value ? params.value : '-'}
        </p>
      );
    }
    case 'address': {
      return <p className={tableItemCN()}>{params.value ? params.value : '-'}</p>;
    }
    default:
      return (
        <p className={tableItemCN()}>
          {Number(params.value) || Number(params.value) == 0
            ? Number(params.value).toLocaleString('ru-RU')
            : params.value
            ? params.value
            : '-'}
        </p>
      );
  }
};
