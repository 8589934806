import { HelpItem, HelpLink } from '../view-models/help-item';
import { linksUrl } from './linksUrl';

export const helpItems: HelpItem[] = [
  new HelpItem(
    1,
    'Как подключить рекламный кабинет Wildberries к XSTAT?',
    'Для этого необходимо создать ключ API в личном кабинете WB и добавить его на сайте XSTAT в разделе  ',
    new HelpLink('Профиль — Ключи.',  linksUrl.profile + linksUrl.profileKeys),
  ),
  new HelpItem(
    2,
    'Как создать API ключ?',
    'Воспользуйтесь ',
    new HelpLink('инструкцией.', '/' + linksUrl.instructions),
  ),
];
