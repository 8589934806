export const linksUrl = {
  home: '/analytics',
  main: '/main',
  analyitcs: '/analytics/',
  analyticsProduct: 'products',
  analyitcsCategory: 'categories',
  analyitcsSeller: 'sellers',
  analyticsBrand: 'brands',
  analyticsRating: 'rating',
  subcategory: '/subcategory',
  login: '/login',
  register: '/register',
  profile: '/profile',
  profileInfo: '/info',
  profileTariffs: '/tariffs',
  profileRate: 'rate',
  profileHistory: '/history',
  profileKeys: '/keys',
  profileNotices: 'notices',
  promotion: '/promotion',
  promotionMonitoring: 'monitoring',
  promotionCampanings: 'campaigns',
  promotionAutoCampanyCard: 'auto-campany-сard',
  promotionSearchCampanyCard: 'search-campany-сard',
  promotionManage: 'manage',
  promotionQueries: 'queries',
  product: '/product',
  favorite: '/favorite',
  seller: '/seller',
  rating: '/rating',
  brand: '/brand',
  passwordRecovery: '/recovery',
  politics: '/files/politics.pdf',
  offer: '/files/offer.pdf',
  help: '/help',
  autobider: '/autobider',
  instructions: 'instructions',
  root: '/',
};
