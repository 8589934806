import { FC, useState } from 'react';
import './category-selected-item.scss';
import { bemCN } from '../../configs/bem-classname';
import Popup from '../../ui/popup/popup';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { CategoryTree } from '../../view-models/category';
import { useNavigate } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';

interface CategorySelectedItemProps {
  id?: string;
  text: string;
  count: number;
  items: any[];
}

const categorySelectedItemCn = bemCN('category-selected-item');

export const CategorySelectedItem: FC<CategorySelectedItemProps> = ({ id, text, count, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cheked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const navigate = useNavigate();

  const onClickCheckAll = () => {
    if (isCheckAll) {
      let emtyIds: string[] = [];
      setChecked([...emtyIds]);
    } else {
      let allIds: string[] = [];
      const extractVal = (items: CategoryTree[]) => {
        items.forEach((e) => {
          allIds.push(e.value);
          if (e.children) {
            extractVal(e.children);
          }
        });
      };
      extractVal(items);
      setChecked(allIds);
    }
    setIsCheckAll(!isCheckAll);
  };

  const onClickApply = () => {
    navigate(linksUrl.subcategory + '?themeCategories=' + cheked.join(','));
  };

  return (
    <div id={id} className={categorySelectedItemCn()}>
      <div onClick={() => setIsOpen(!isOpen)} className={categorySelectedItemCn('button')}>
        <div className={categorySelectedItemCn('color-block')}></div>
        <span>{text}</span>
        <span>{count} шт.</span>
      </div>
      <Popup isShow={isOpen} setIsShow={setIsOpen}>
        <div className={categorySelectedItemCn('check-all')}>
          <p>Выбрать все</p>
          <div
            onClick={onClickCheckAll}
            className={isCheckAll ? categorySelectedItemCn('active') : ''}
          ></div>
        </div>
        <CheckboxTree
          nodes={items}
          checked={cheked}
          expanded={expanded}
          onCheck={(checked) => setChecked(checked)}
          onExpand={(expanded) => setExpanded(expanded)}
        />
        <div className={categorySelectedItemCn('buttons')}>
          <Button
            onClick={() => {
              setChecked([]);
              setIsCheckAll(false);
            }}
            text="Сбросить"
            color={ColorsButton.white}
            size={SizesButton.big}
          />
          <Button
            onClick={onClickApply}
            noDisabled={cheked.length > 0 ? false : true}
            text="Применить"
            color={ColorsButton.violet}
            size={SizesButton.big}
          />
        </div>
      </Popup>
    </div>
  );
};
